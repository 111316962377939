import React, { useEffect, useState, useRef, memo } from 'react'
import { Input, AutoComplete } from 'antd'
import { useNavigate, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { HeaderSearchWrapper } from './HeaderSearch.styles'
import { fetchSites, selectSite, getSites } from '../../store/siteSlice'

const HeaderSearch = () => {
  const sitesData = useSelector(getSites)
  const inputElement = useRef()
  const [options, setOptions] = useState([])
  const [searchString, setSearchString] = useState('')
  const [searchData, setSearchData] = useState([])
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()

  const viewDetailsClickHandler = (data) => {
    dispatch(selectSite(data))
    setSearchString('')

    if (!location.pathname.includes('/admin')) {
      navigate(`site/${data.site_info_id}`)
    }
  }

  useEffect(() => {
    dispatch(fetchSites())
  }, [dispatch])

  useEffect(() => {
    if (!sitesData.loading) {
      setSearchData(sitesData.data)
    }
  }, [sitesData])

  const handleSearch = (value) => {
    var searchRegex = new RegExp(value, 'ig')
    var searchString = value?.toLowerCase()
    var sSites = searchData.filter(
      (p) =>
        p.regional_site_name?.toLowerCase().includes(searchString) ||
        p.city?.toLowerCase().includes(searchString) ||
        p.state?.toLowerCase().includes(searchString) ||
        p.country?.toLowerCase().includes(searchString),
    )
    var opts = []
    sSites.forEach((s) => {
      opts.push({
        value: s.regional_site_name,
        label: (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}
          >
            <span
              style={{ fontSize: 14 }}
              dangerouslySetInnerHTML={{
                __html: s.regional_site_name.replace(
                  searchRegex,
                  (v) => `<b>${v}</b>`,
                ),
              }}
            ></span>
            <span
              style={{ fontSize: 10, textAlign: 'left' }}
              dangerouslySetInnerHTML={{
                __html: `${s.city?.replace(
                  searchRegex,
                  (v) => `<b>${v}</b>`,
                )}, ${s.state?.replace(
                  searchRegex,
                  (v) => `<b>${v}</b>`,
                )}, ${s.country?.replace(searchRegex, (v) => `<b>${v}</b>`)}`,
              }}
            ></span>
          </div>
        ),
      })
    })
    setOptions(opts)
  }

  const onSelect = (value) => {
    const seletedSite = searchData.find((s) => s.regional_site_name === value)

    if (seletedSite) {
      viewDetailsClickHandler(seletedSite)
    }
  }

  const handleChange = (value) => {
    setSearchString(value)
  }

  return (
    <HeaderSearchWrapper>
      <AutoComplete
        className="select"
        options={options}
        value={searchString}
        onSelect={onSelect}
        onSearch={handleSearch}
        onChange={handleChange}
        allowClear
        showSearch
      >
        <Input.Search
          ref={inputElement}
          size="large"
          placeholder="Search for a site name"
          value={searchString}
        />
      </AutoComplete>
    </HeaderSearchWrapper>
  )
}

export default memo(HeaderSearch)
