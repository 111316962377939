import { useCallback, useEffect, useState, memo } from 'react'
import { YearRangePicker } from 'react-year-range-picker'
import moment from 'moment'
import { flatten, sortBy, values } from 'lodash'
import { KeeTimelineWrapper, KeeTimelineHeader } from './KeeTimeline.styles'
import Filter from '../Filter'
import KeeActivities from './KeeActivities'
import CONSTANTS from '../../constants'
import { GetAPIRequest } from '../../services'

const getYearRangeValues = (data) => {
  const year = moment(data?.[0].startDate || new Date()).year()
  const yearRange = { startYear: year, endYear: year + 1 }

  data?.forEach(({ startDate }) => {
    const year = moment(startDate).year()
    if (yearRange.startYear > year) {
      yearRange.startYear = year
    } else if (yearRange.endYear < year) {
      yearRange.endYear = year
    }
  })

  return yearRange
}

const filterOptions = [
  { id: 'All', name: 'All' },
  { id: 'clinicaltrial', name: 'Clinical Trials' },
  { id: 'event', name: 'Events' },
  { id: 'guideline', name: 'Guidelines' },
  { id: 'mediamention', name: 'Media Mentions' },
  { id: 'publication', name: 'Publications' },
]

const KeeTimeline = ({ keeId }) => {
  const [selectedFilter, setSelectedFilter] = useState('All')
  const [keeTimeLineData, setKeeTimeLineData] = useState({
    loading: false,
    data: [],
    error: null,
  })
  const [filteredData, setFilteredData] = useState([])
  const [yearRange, setYearRange] = useState()
  const [selectedYearRange, setSelectedYearRange] = useState()

  const handleFilterChange = useCallback(
    (yearRange, filter) => {
      if (filter === 'All' && !yearRange) {
        setFilteredData(keeTimeLineData.data)
      } else {
        const activities = keeTimeLineData.data?.filter(
          ({ type, startDate }) => {
            const year = moment(startDate || new Date()).year()

            return (
              (filter === 'All' || filter === type?.toLowerCase()) &&
              (!yearRange ||
                (year >= yearRange.startYear && year <= yearRange.endYear))
            )
          },
        )

        setFilteredData(activities)
      }
    },
    [keeTimeLineData],
  )

  const getKeeTimelineData = useCallback((keeId) => {
    setKeeTimeLineData({ loading: true, data: [], error: null })
    GetAPIRequest({
      url: `${CONSTANTS.api.getKeeTimeLineData}/${keeId}`,
    })
      .then((res) => {
        const data = sortBy(flatten(values(res)), 'startDate').reverse()
        const yearRangeData = getYearRangeValues(data)
        setFilteredData(data)
        setKeeTimeLineData({ loading: false, data, error: null })
        setYearRange(yearRangeData)
      })
      .catch((error) => {
        setKeeTimeLineData({ loading: false, data: [], error: error.message })
      })
  }, [])

  useEffect(() => {
    if (keeId) {
      getKeeTimelineData(keeId)
    }
  }, [getKeeTimelineData, keeId])

  return (
    <KeeTimelineWrapper>
      <KeeTimelineHeader>
        <div className="title">Scientific Activities</div>
        {keeTimeLineData.data.length > 0 && (
          <div className="timeline-header">
            <div>
              <div className="filed-label">Timeframe</div>
              <YearRangePicker
                minYear={yearRange?.startYear}
                maxYear={yearRange?.endYear}
                onSelect={(startYear, endYear) => {
                  setSelectedYearRange({ startYear, endYear })
                  handleFilterChange({ startYear, endYear }, selectedFilter)
                }}
                startYear={selectedYearRange?.startYear}
                endYear={selectedYearRange?.endYear}
              />
            </div>
            <Filter
              title={'Filter by'}
              placeholder=""
              showSearch={false}
              selectedValue={selectedFilter}
              data={filterOptions}
              onChange={(value) => {
                setSelectedFilter(value)
                handleFilterChange(selectedYearRange, value)
              }}
            />
          </div>
        )}
      </KeeTimelineHeader>
      <KeeActivities
        keeTimeLineData={{
          loading: keeTimeLineData.loading,
          data: filteredData,
        }}
      />
    </KeeTimelineWrapper>
  )
}

export default memo(KeeTimeline)
