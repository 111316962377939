import React, { memo, useEffect, useRef } from 'react'
import { useCallback } from 'react'
import * as am5 from '@amcharts/amcharts5'
import am5geodata_data_countries2 from '@amcharts/amcharts5-geodata/data/countries2'
import am5geodata_usaLow from '@amcharts/amcharts5-geodata/usaLow'
import * as am5map from '@amcharts/amcharts5/map'
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated'
import { geoCylindricalStereographic } from 'd3-geo-projection'
import { SiteNetworkMapWrapper } from './SiteNetworkMap.styles'

const SiteNetworkMap = ({ countryCode, data, onSiteSelect = () => {} }) => {
  const rootElement = useRef()

  const loadSiteNetworkInfo = useCallback(
    (siteNetworkData) => {
      if (rootElement.current != null) {
        rootElement.current.container.children.clear()
      } else {
        rootElement.current = am5.Root.new('siteNetwork')
      }

      let root = rootElement.current

      var countrySeries,
        siteBubbleSeries,
        originSiteBubbleSeries,
        pointSeries1,
        chart,
        lineSeries,
        arrowSeries,
        originSiteCircleTemplate,
        siteCircleTemplate
      root.setThemes([am5themes_Animated.new(root)])

      chart = root.container.children.push(
        am5map.MapChart.new(root, {
          panX: 'translateX',
          panY: 'translateY',
          projection: geoCylindricalStereographic(),
        }),
      )

      chart.chartContainer.set(
        'background',
        am5.Rectangle.new(root, {
          fill: am5.color(0x60bce5),
          fillOpacity: 1.0,
          stroke: am5.color(0x60bce5),
        }),
      )

      let charttooltip = am5.Tooltip.new(root, {
        getFillFromSprite: false,
        autoTextColor: false,
      })
      charttooltip.label.setAll({
        fill: am5.color(0x3e3e3e),
        fillOpacity: 1,
      })
      charttooltip.get('background').setAll({
        fill: am5.color(0xffffff),
        fillOpacity: 1,
      })
      chart.set('tooltip', charttooltip)

      countrySeries = chart.series.push(
        am5map.MapPolygonSeries.new(root, {
          visible: false,
        }),
      )
      countrySeries.mapPolygons.template.setAll({
        tooltipText: '',
        interactive: true,
        stroke: am5.color(0xe8c656),
        strokeWidth: 0.5,
        fillGradient: am5.LinearGradient.new(root, {
          stops: [
            {
              color: am5.color(0xe5e6ca),
              brighten: 0,
            },
            {
              color: am5.color(0xe5e6ca),
              brighten: 0,
            },
          ],
        }),
      })

      siteCircleTemplate = am5.Template.new(root)
      originSiteCircleTemplate = am5.Template.new(root)

      pointSeries1 = chart.series.push(
        am5map.MapPointSeries.new(root, { visible: false, positionOnLine: 1 }),
      )
      pointSeries1.bullets.push(function () {
        return am5.Bullet.new(root, {
          sprite: am5.Label.new(root, {
            centerX: am5.p50,
            centerY: am5.p50,
            text: '{name}',
            fill: am5.color(0x999999),
            populateText: true,
            fillOpacity: 0.6,
            cursorOverStyle: 'pointer',
            fontSize: '8px',
          }),
        })
      })

      lineSeries = chart.series.push(am5map.MapLineSeries.new(root, {}))
      lineSeries.mapLines.template.setAll({
        stroke: am5.color(0x9d3878),
        strokeWidth: 2,
        strokeOpacity: 1,
      })

      arrowSeries = chart.series.push(am5map.MapPointSeries.new(root, {}))

      arrowSeries.bullets.push(function () {
        var arrow = am5.Graphics.new(root, {
          fill: am5.color(0x9d3878),
          stroke: am5.color(0x9d3878),
          draw: function (display) {
            display.moveTo(0, -3)
            display.lineTo(8, 0)
            display.lineTo(0, 3)
            display.lineTo(0, -3)
          },
        })

        return am5.Bullet.new(root, {
          sprite: arrow,
        })
      })
      siteBubbleSeries = chart.series.push(
        am5map.MapPointSeries.new(root, {
          calculateAggregates: true,
          valueField: 'patients',
        }),
      )
      siteBubbleSeries.bullets.push(function () {
        var container = am5.Container.new(root, {})
        const siteTooltipLabelStyles =
          '#454545 fontSize: 12px; fontWeight: 500; width: 160px'
        const siteTooltipValseStyles = '#888585 fontSize: 12px;'
        var siteCircle = container.children.push(
          am5.Circle.new(
            root,
            {
              tooltipY: 0,
              fill: am5.color(0x9d3878),
              stroke: root.interfaceColors.get('background'),
              strokeWidth: 2,
              radius: 6,
              cursorOverStyle: 'pointer',
              tooltipText: `[fontSize: 14px; #af0062 bold]{name}[/]
[${siteTooltipLabelStyles}]Referred Patients:[/] [${siteTooltipValseStyles}]{patients}[/]
[${siteTooltipLabelStyles}]Distance:[/] [${siteTooltipValseStyles}]{distance}[/]`,
            },
            siteCircleTemplate,
          ),
        )
        siteCircle.template.events.off('click')
        siteCircle.template.events.on('click', function (ev) {
          if (ev.target.dataItem.dataContext.id) {
            onSiteSelect(ev.target.dataItem.dataContext)
          }
        })
        return am5.Bullet.new(root, {
          sprite: container,
        })
      })

      originSiteBubbleSeries = chart.series.push(
        am5map.MapPointSeries.new(root, {}),
      )
      originSiteBubbleSeries.bullets.push(function () {
        var container = am5.Container.new(root, {})
        container.children.push(
          am5.Circle.new(
            root,
            {
              tooltipY: 0,
              fill: am5.color(0xffba00),
              stroke: root.interfaceColors.get('background'),
              strokeWidth: 2,
              radius: 6,
              cursorOverStyle: 'pointer',
              tooltipText: `[fontSize: 14px; #af0062 bold]{name}[/]`,
            },
            originSiteCircleTemplate,
          ),
        )
        return am5.Bullet.new(root, {
          sprite: container,
        })
      })

      if (siteNetworkData.length) {
        var inboundSites = []
        originSiteBubbleSeries.data.setAll([
          {
            id: siteNetworkData[0].to_site_id,
            name: siteNetworkData[0].to_site,
            geometry: {
              type: 'Point',
              coordinates: [
                siteNetworkData[0].to_site_longitude,
                siteNetworkData[0].to_site_latitude,
              ],
            },
          },
        ])
        siteNetworkData.map((site) => {
          var distance = parseFloat(site.distance).toFixed(1)
          if (isNaN(distance)) {
            distance = ''
          } else {
            distance = distance + ' miles'
          }
          inboundSites.push({
            id: site.from_site_id,
            name: site.from_site,
            patients: site.patients,
            distance: distance,
            geometry: {
              type: 'Point',
              coordinates: [site.from_site_longitude, site.from_site_latitude],
            },
          })
        })
        console.log('siteNetworkData.inboundSites', inboundSites)
        const sites = inboundSites
        siteBubbleSeries.data.setAll(sites)

        // site coordinates
        var originLongitude = siteNetworkData[0].to_site_longitude
        var originLatitude = siteNetworkData[0].to_site_latitude

        am5.array.each(inboundSites, function (did) {
          var lineDataItem = lineSeries.pushDataItem({
            geometry: {
              type: 'LineString',
              coordinates: [
                [did.geometry.coordinates[0], did.geometry.coordinates[1]],
                [originLongitude, originLatitude],
              ],
            },
          })

          arrowSeries.pushDataItem({
            lineDataItem: lineDataItem,
            positionOnLine: 0.5,
            autoRotate: true,
          })
        })

        countrySeries.events.on('datavalidated', function () {
          chart.zoomToGeoPoint(
            { longitude: originLongitude, latitude: originLatitude },
            6,
          )
        })

        countrySeries.show()
        pointSeries1.show()
      }

      setTimeout(() => {
        UpdateDataSite({ id: countryCode })
      }, 100)

      // Make stuff animate on load
      chart.appear(1000, 100)

      function UpdateDataSite(data) {
        var filename = ''
        var country = am5geodata_data_countries2[data.id]
        if (country.maps.length > 0) {
          if (country.maps[0] === 'usaLow') {
            filename = 'usaLow.json'
          } else {
            filename = country.maps[0] + '.json'
          }
        }

        if (data.id == 'US') {
          //chart.set("projection", am5map.geoAlbersUsa());
          updateMapData(am5geodata_usaLow)
        } else {
          showData()
        }

        function showData() {
          Promise.all([
            fetch('/libs/amcharts5-geodata/json/' + filename, {
              cache: 'force-cache',
            }).then(function (response) {
              console.log(response)
              return response.json()
            }),
          ]).then((results) => {
            var geodata = results[0]
            updateMapData(geodata)
          })
        }

        function updateMapData(geodata) {
          countrySeries.setAll({
            geoJSON: geodata,
          })

          setTimeout(() => {
            pointSeries1.data.setAll([])
            countrySeries.mapPolygons.each(function (polygon) {
              pointSeries1.pushDataItem({
                polygonId: polygon.dataItem.dataContext.id,
                name: polygon.dataItem.dataContext.name,
              })
            })
          }, 10)
        }
      }
    },
    [countryCode, onSiteSelect],
  )

  useEffect(() => {
    loadSiteNetworkInfo(data)
  }, [data, loadSiteNetworkInfo])

  return (
    <SiteNetworkMapWrapper>
      <SiteNetworkMapWrapper id="siteNetwork"></SiteNetworkMapWrapper>
    </SiteNetworkMapWrapper>
  )
}

export default memo(SiteNetworkMap)
