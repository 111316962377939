import styled from 'styled-components'

export const LegendContainerWrapper = styled.div`
  min-width: 550px;
  max-width: 550px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0px 10px;
`

export const ChartWrapper = styled.div`
  display: flex;
  width: 100%;
  .chart-loader {
    display: flex;
    width: 100%;
    flex-direction: column;
  }
`
