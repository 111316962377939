import { useEffect, useState } from 'react'
import { checkAuth, getCookie } from '../utils/cookie-helper'
import { GetAPIRequest } from '../services'
import CONFIG from '../constants'

const useAuth = () => {
  const [userDetails, setUserDetails] = useState({
    loading: false,
    data: null,
    error: null,
  })

  useEffect(() => {
    const token = getCookie('access-token')

    if (!token) {
      checkAuth()
    } else {
      getUserDetails()
    }
  }, [])

  const getUserDetails = () => {
    const prid_id = getCookie('sub')
    setUserDetails({ loading: true, data: null, error: null })
    GetAPIRequest({
      url: `${CONFIG.api.getUserDetails}/${prid_id}`,
    })
      .then(({ data }) => {
        const isAdmin =
          data != null && (data.role_id === 1 || data.role_id === 2)

        setUserDetails({
          data: { ...data, isAdmin },
          loading: false,
          error: null,
        })
      })
      .catch((err) => {
        console.log(err)
        setUserDetails({ loading: false, data: null, error: err })
      })
  }

  return userDetails
}

export default useAuth
