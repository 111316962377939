import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import CONSTANTS from '../constants'
import { getCookie } from '../utils/cookie-helper'

export const siteNetworkApi = createApi({
  reducerPath: 'siteNewwork',
  baseQuery: fetchBaseQuery({
    baseUrl: CONSTANTS.api.getSiteNetworkData,
    prepareHeaders: (headers) => {
      const access_token = getCookie('access-token')

      if (access_token && access_token !== '') {
        headers.set('authorization', 'Bearer ' + access_token)
      }

      return headers
    },
  }),
  endpoints: (builder) => ({
    getSiteNewworkByName: builder.query({
      query: ({ siteName, year }) =>
        siteName ? `sitename/${siteName}/year/${year}` : '',
    }),
  }),
})

export const { useGetSiteNewworkByNameQuery } = siteNetworkApi
