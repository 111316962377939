import styled from 'styled-components'

export const KeeTimelineWrapper = styled.div`
  display: block;
`

export const KeeTimelineHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 24px;

  .title {
    font-size: 18px;
    font-weight: 500;
    color: #8a0051;
    width: 20%;
  }

  .filed-label {
    font-size: 14px;
    text-align: left;
    width: 200px;
    margin-bottom: 5px;
    color: #868686;
  }

  .timeline-header {
    display: flex;
    justify-content: flex-end;
    width: 80%;

    .yrp-container {
      border: 1px solid #d9d9d9;
    }

    .yrp-picker-box {
      padding: 3px 8px;
    }

    .yrp-arrow-button i {
      border: solid #bfbfbf;
      border-width: 0 1px 1px 0;
      padding: 3px;
      position: relative;
      top: -4px;
    }

    .yrp-picker-text {
      color: #4d4f5c;
    }
  }
`

export const KeeActivitiesWrapper = styled.div`
  .loading {
    margin: 16px auto;
    width: 100%;
  }
`

export const ListHeader = styled.div`
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  color: #fff;
  background-color: #43425d;
  margin-top: 1rem;
  .what {
    padding: 8px 24px;
    width: 70%;
  }
  .when {
    padding: 8px 24px;
    width: 30%;
  }
`

export const ListContent = styled.div`
  display: flex;
  flex-direction: column;
`

export const ListItem = styled.div`
  display: flex;
  align-items: flex-start;
  border-bottom: solid 1px #ccc;

  .icon {
  }
  .content-wrapper {
    padding-left: 24px;
  }
  .what {
    display: flex;
    align-items: flex-start;
    width: 70%;
    padding: 8px 24px;
  }
  .when {
    width: 30%;
    font-weight: bold;
    padding: 8px 24px;
  }
  .center {
    text-align: center;
  }
  .date,
  .heading {
    font-weight: bold;
    color: #454545;
  }
  .details {
    color: #454545;
  }
  .session {
    margin: 16px 0;
  }
  .date {
    font-weight: bold;
    color: #454545;
  }
`
