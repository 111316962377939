import { useCallback, useEffect, useState, memo } from 'react'
import { Button, Row, Tag } from 'antd'
import { EditOutlined, CheckOutlined } from '@ant-design/icons'
import { SiteDetailsWrapper, SiteTitle } from './SiteDetails.styles'
import { useDispatch, useSelector } from 'react-redux'
import { getAdminSiteDetails } from '../../../store/siteSlice'
import { selectKee } from '../../../store/keeSlice'
import SiteCard from '../SiteCard'
import KeeList from '../KeeList'
import { NavLink, useNavigate, useParams } from 'react-router-dom'
import CONSTANTS from '../../../constants'
import { GetAPIRequest } from '../../../services'

const SiteDetails = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const siteDetails = useSelector(getAdminSiteDetails)
  const [siteData] = useState({
    loading: false,
    data: siteDetails,
    error: null,
  })
  const [keeData, setKeeData] = useState({
    loading: false,
    data: [],
    error: null,
  })
  const { id } = useParams()

  const handleKeeSelect = (keeDetails) => {
    dispatch(selectKee(keeDetails))
    navigate(`kee/${keeDetails.id}`)
  }

  const fetchKees = useCallback((id) => {
    setKeeData({ loading: true, data: [], error: null })
    GetAPIRequest({
      url: `${CONSTANTS.api.getSiteKees}/${id}`,
    })
      .then((res) => {
        setKeeData({ loading: false, data: res, error: null })
      })
      .catch((error) => {
        setKeeData({ loading: false, data: [], error: error.message })
      })
  }, [])

  useEffect(() => {
    if (id) {
      fetchKees(id)
    }
  }, [fetchKees, id])

  return (
    <SiteDetailsWrapper>
      <NavLink className="back-button" to="/admin/sites">
        Back to Sites
      </NavLink>
      <Row justify="space-between" className="top-bar">
        <div>
          <SiteTitle>
            {siteDetails?.regional_site_name?.replace('N/A', 'Unavailable')}
          </SiteTitle>
          <div className="sub-title">
            {siteDetails.site_address && siteDetails.site_address + ', '}
            {siteDetails.city && siteDetails.city + ', '}
            {siteDetails.state && siteDetails.state + ', '}
            {siteDetails.country_code}
          </div>
        </div>
        <div className="actions">
          {siteDetails.is_approved && (
            <Tag icon={<CheckOutlined />} color="#87d068">
              Approved
            </Tag>
          )}
          <Button
            className="edit-button"
            style={{ alignContent: 'flex-end' }}
            icon={<EditOutlined />}
            onClick={() => navigate(`/admin/edit/site/${id}`)}
          >
            Edit
          </Button>
        </div>
      </Row>
      <SiteCard
        data={siteData.data}
        loading={siteData.loading}
        hideViewDetailButton
        showEditButton={window.location.pathname.includes('admin')}
      />
      <KeeList
        keeData={keeData}
        siteName={siteDetails?.regional_site_name}
        hideViewDetails={true}
        onKeeSelect={handleKeeSelect}
      />
    </SiteDetailsWrapper>
  )
}

export default memo(SiteDetails)
