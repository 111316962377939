import React, { memo } from 'react'
import { Divider, Spin, Skeleton, Empty } from 'antd'
import {
  SiteCardWrapper,
  SiteDetailsWrapper,
  SiteDetails,
  Datafield,
  DataFieldHeader,
  DataFieldValue,
  SiteStudies,
} from './SiteCard.styles'
import { isEmpty } from 'lodash'

const getSiteType = (siteType) => {
  if (isNaN(+siteType)) {
    return siteType
  } else {
    return siteType == '1' ? 'AZ Site' : 'Opportunity Site'
  }
}

const getAvgStartUptime = (avg_startup_time) => {
  if (!avg_startup_time || avg_startup_time === 'N/A') {
    return 'Unavailable'
  } else {
    return (+avg_startup_time).toFixed(2)
  }
}

const SiteCard = ({ data = {}, loading = false }) => {
  return (
    <SiteCardWrapper className={`${loading ? 'loading' : ''}`}>
      {loading ? (
        <>
          <Spin />
          <Skeleton active />
        </>
      ) : isEmpty(data) ? (
        <Empty description="Site data unavailable" />
      ) : (
        <>
          <SiteDetailsWrapper>
            <SiteDetails>
              <Datafield>
                <DataFieldHeader>Indication</DataFieldHeader>
                <DataFieldValue>
                  {data.indication_type?.replace('N/A', 'Unavailable')}
                </DataFieldValue>
              </Datafield>
              <Datafield>
                <DataFieldHeader>Site Type</DataFieldHeader>
                <DataFieldValue>{getSiteType(data.site_type)}</DataFieldValue>
              </Datafield>
              <Datafield>
                <DataFieldHeader>Total AZ Trials</DataFieldHeader>
                <DataFieldValue>
                  {(data.indication_type === 'Breast Cancer'
                    ? data.total_az_studies
                    : data.no_of_study) || 'Unavailable'}
                </DataFieldValue>
              </Datafield>
              <Datafield>
                <DataFieldHeader>Total KEE</DataFieldHeader>
                <DataFieldValue>
                  {data.total_kee || 'Unavailable'}
                </DataFieldValue>
              </Datafield>
              {data.country_code?.toLowerCase() == 'us' && (
                <Datafield>
                  <DataFieldHeader>Diversity Index</DataFieldHeader>
                  <DataFieldValue>
                    {data.diversity_index?.replace('N/A', 'Unavailable')}
                  </DataFieldValue>
                </Datafield>
              )}
            </SiteDetails>
            <Divider />
            <SiteDetails>
              {data.country_code?.toLowerCase() == 'us' && (
                <Datafield>
                  <DataFieldHeader>Patient Volume</DataFieldHeader>
                  <DataFieldValue>
                    {data.patient_volume || 'Unavailable'}
                  </DataFieldValue>
                </Datafield>
              )}
              <Datafield>
                <DataFieldHeader>
                  Standard Contract Language Available
                </DataFieldHeader>
                <DataFieldValue>
                  {data.standard_contract_language_available?.replace(
                    'N/A',
                    'Unavailable',
                  ) || 'Unavailable'}
                </DataFieldValue>
              </Datafield>
              <Datafield>
                <DataFieldHeader>Site status</DataFieldHeader>
                <DataFieldValue>{data.status || 'Unavailable'}</DataFieldValue>
              </Datafield>

              <Datafield>
                <DataFieldHeader>Early Phase Potential</DataFieldHeader>
                <DataFieldValue>
                  {data.early_phase || 'Unavailable'}
                </DataFieldValue>
              </Datafield>
            </SiteDetails>
            <Divider />
            <SiteStudies>
              <div>
                <DataFieldHeader>AZ Studies</DataFieldHeader>
                <DataFieldValue>
                  {data.study_names || 'Unavailable'}
                </DataFieldValue>
              </div>
            </SiteStudies>
          </SiteDetailsWrapper>
        </>
      )}
    </SiteCardWrapper>
  )
}

export default memo(SiteCard)
