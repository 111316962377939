const groupBy = function (xs, key) {
    return xs.reduce(function (rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
    }, {});
};

const groupSiteTypes = (res, item) => {
    let uniqueSites = [],
        cSites = [],
        cAZSites = [],
        cLungCancerSites = [],
        cBreastCancerSites = [];

    res.forEach((p) => {
        if (p.country_code === item.country_code) {
            cSites.push(p);
            const indication = p.indication_type?.toLowerCase();
            if (p.site_type === "1") {
                cAZSites.push(p);
            }

            if (indication.includes("lung cancer")) {
                cLungCancerSites.push(p);
            }

            if (indication.includes("breast cancer")) {
                cBreastCancerSites.push(p);
            }

            if (uniqueSites.indexOf(p.regional_site_name) < 0) {
                uniqueSites.push(p.regional_site_name);
            }
        }
    });

    return ({
        uniqueSites,
        cSites,
        cAZSites,
        cLungCancerSites,
        cBreastCancerSites
    });
};

const mapSiteData = countryData => {
    const selIndication = sessionStorage.getItem('selIndication');
    const siteMapData = [];

    countryData.forEach((_c) => {
        if (_c.value > 0) {
            const _mc = _c;
            if (selIndication == 0) {
                _mc.value = _c.lungsites + _c.breastcancersites;
                siteMapData.push(_mc);
            } else if (selIndication == 1 && _c.lungsites > 0) {
                _mc.value = _c.lungsites;
                siteMapData.push(_mc);
            } else if (selIndication == 2 && _c.breastcancersites > 0) {
                _mc.value = _c.breastcancersites;
                siteMapData.push(_mc);
            }
        }
    });

    return siteMapData;
}

export const groupSiteData = res => {
    if (!sessionStorage.getItem("selIndication")) {
        sessionStorage.setItem("selIndication", 1);
    }

    const countryData = [];
    const stateData = [];
    const countires = [];
    const states = [];

    res.forEach((r, index) => {
        r = { ...r };
        res[index] = r;

        const country_code = r.country_code === "UK" ? "GB" : r.country_code;
        const state = r.state;

        if (countires.indexOf(country_code) > -1) {
        } else {
            let cNonAZSites = 0;
            const {
                uniqueSites = [],
                cSites = [],
                cAZSites = [],
                cLungCancerSites = [],
                cBreastCancerSites = []
            } = groupSiteTypes(res, r);

            countires.push(country_code);
            cNonAZSites = cSites.length - cAZSites.length;

            const cPatientVolume = cSites.reduce(
                (sum, site) => (sum += site.patient_volume),
                0
            );

            countryData.push({
                id: country_code,
                name: r.country,
                value: cLungCancerSites.length,
                azsites: cAZSites.length,
                nonazsites: cNonAZSites,
                lungsites: cLungCancerSites.length,
                breastcancersites: country_code === "US" ? cBreastCancerSites.filter(s => s.categories.some(c => c.category_name === "Top 30 for TALT")).length : cBreastCancerSites.length,
                overlappingsites: cSites.length - uniqueSites.length,
                patientVolume: cPatientVolume,
            });
        }

        const latlong = [r.lon, r.lat];

        if (states.indexOf(state) > -1) {
        } else {
            states.push(state);
            const sSites = res.filter((p) => p.state === state);
            const sAZSites = res.filter(
                (p) => p.state === state && p.site_type === "1"
            );
            const sNonAZSites = sSites.length - sAZSites.length;
            const sLungCancerSites = res.filter(
                (p) =>
                    p.state === state && p.indication_type?.toLowerCase().includes("lung cancer")
            );
            const sPatientVolume = sSites.reduce(
                (sum, site) => (sum += site.patient_volume),
                0
            );

            stateData.push({
                id: state,
                country: country_code,
                name: r.state,
                geometry: {
                    type: "Point",
                    coordinates: latlong,
                },
                value: sSites.length,
                azsites: sAZSites.length,
                nonazsites: sNonAZSites,
                lungsites: sLungCancerSites.length,
                othersites: sSites.length - sLungCancerSites.length,
                patientVolume: sPatientVolume,
            });
        }

        r.geometry = {
            type: "Point",
            coordinates: latlong,
        };
        r.value = r.patient_volume;

        if (r.site_type === "1") {
            r.site_type = "AZ Site";
        } else {
            r.site_type = "Opportunity Site";
        }

        r.country_code = r.country_code === "UK" ? "GB" : r.country_code;
    });

    const siteMapData = mapSiteData(countryData);

    return { data: res, stateData, siteMapData }
};

export const resetSessionData = () => {
    sessionStorage.removeItem("selBreastSiteCategory");
    sessionStorage.removeItem("selBreastSiteSubCategory");
    sessionStorage.removeItem("selBreastTaskForceSiteCategory");
    sessionStorage.removeItem("selBreastNetworkSiteCategory");
    sessionStorage.removeItem("selType");
    sessionStorage.removeItem("selectedCountry");
    sessionStorage.removeItem("selectedCountryCode");
    sessionStorage.removeItem("selCategory");
    sessionStorage.removeItem("selTop30SiteCategory");
    sessionStorage.removeItem("selSiteProfile");
    sessionStorage.removeItem("selSiteType");
    sessionStorage.removeItem("selDiversity");
};

export const setSessionData = (country, country_code) => {
    sessionStorage.setItem("selectedCountry", country);
    sessionStorage.setItem("selBreastSiteCategory", "Top 30 for TALT");
    sessionStorage.setItem("selectedCountryCode", country_code);
    sessionStorage.setItem("selBreastSiteSubCategory", "");
    sessionStorage.setItem("selBreastTaskForceSiteCategory", "All");
    sessionStorage.setItem("selBreastNetworkSiteCategory", "All");
    sessionStorage.setItem("selType", "All");
    sessionStorage.setItem("selCategory", "All");
    sessionStorage.setItem("selTop30SiteCategory", "All");
};
