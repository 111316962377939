import { useEffect, memo } from 'react'
import { Spin, Skeleton, Row, Space, Button } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import SitesTable from '../SitesTable'
import Filter from '../../../components/Filter'
import {
  fetchAdminSites,
  fetchSiteCountries,
  getAdminSites,
  getSiteCountries,
  selectAdminSite,
} from '../../../store/siteSlice'
import {
  selectCountry,
  getSelectedCountry,
  selectAdminIndication,
  getSelectedAdminIndication,
} from '../../../store/filterSlice'

let indicationType = 'Breast Cancer'
const indications = [
  { id: 'Lung Cancer', name: 'Lung Cancer' },
  { id: 'Breast Cancer', name: 'Breast Cancer' },
]

const Sites = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const selectedCountry = useSelector(getSelectedCountry)
  const selectedIndication = useSelector(getSelectedAdminIndication)
  let { loading, data } = useSelector(getAdminSites)
  const { loading: countriesLoading, data: countries } = useSelector(
    getSiteCountries,
  )

  const handleSearch = () => {
    indicationType = selectedIndication
    dispatch(fetchAdminSites(selectedCountry))
  }

  const onViewDetailsClickHandler = (data) => {
    dispatch(selectAdminSite(data))
    navigate(`/admin/site/${data.id}`)
  }

  const onAddClick = () => {
    dispatch(selectAdminSite(null))
    navigate(`/admin/add/site`)
  }

  useEffect(() => {
    if (!loading && countries?.length === 0) {
      dispatch(fetchSiteCountries())
    }
  }, [])

  return (
    <div>
      <Spin className="loading" spinning={countriesLoading} />
      <Skeleton loading={countriesLoading} active />
      {!countriesLoading && countries?.length > 0 && (
        <Row justify="center" align="bottom">
          <Space align="end">
            <Filter
              title={'Indication'}
              placeholder=""
              showSearch={false}
              selectedValue={selectedIndication}
              data={indications}
              onChange={(value) => {
                dispatch(selectAdminIndication(value))
              }}
            />
            <Filter
              title={'Country'}
              placeholder=""
              showSearch={false}
              selectedValue={selectedCountry}
              data={countries}
              onChange={(value) => dispatch(selectCountry(value))}
            />
            <Button
              type="primary"
              disabled={!selectedCountry || loading}
              onClick={handleSearch}
            >
              Submit
            </Button>
            <Button type="primary" onClick={onAddClick}>
              Add New Site
            </Button>
          </Space>
        </Row>
      )}
      <div>
        <Spin className="loading" spinning={loading} />
        <Skeleton loading={loading} active />
        {!loading && (
          <SitesTable
            indicationType={indicationType}
            data={data.filter(
              (site) => site.indication_type === indicationType,
            )}
            onView={onViewDetailsClickHandler}
          ></SitesTable>
        )}
      </div>
    </div>
  )
}

export default memo(Sites)
