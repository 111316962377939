export const indicationTypes = [
  { id: 1, name: 'LUNG CANCER' },
  { id: 2, name: 'BREAST CANCER' },
]

export const siteType = [
  { id: 'All', name: 'All Sites' },
  { id: 'AZ Sites', name: 'AZ Sites' },
  { id: 'Opportunity Sites', name: 'Opportunity Sites' },
]

export const filterTypes = ['Site Profile', 'Site Type', 'Diversity']

export const bFilterType = [
  { id: 'Site Profile', name: 'Site Profile' },
  { id: 'Site Type', name: 'Site Type' },
  { id: 'Diversity', name: 'Diversity' },
]

export const lFilterType = [
  { id: 'Site Profile', name: 'Site Profile' },
  { id: 'Site Type', name: 'Site Type' },
  { id: 'Diversity', name: 'Diversity' },
]

export const lfilterTypeMenu = ['Site Profile', 'Site Type', 'Diversity']
export const siteTypeMenu = ['All', 'AZ Site', 'Opportunity Site']
export const siteProfileMenu = ['All', 'Community', 'Academic']
export const siteDiversityMenu = ['All', 'High', 'Low']
export const siteCategory1 = ['All', 'Community', 'Academic']

export const siteCategory = [
  'All',
  'Community',
  'Academic',
  'US Oncology',
  'SCRI',
  'D&I hot spot',
  'New Opportunity site',
]

export const top30SiteCategory = [
  'All',
  'Community',
  'Academic',
  'US Oncology',
  'SCRI',
]

export const breastNetworkSiteCategory = ['All', 'US Oncology', 'SCRI']

export const breastTaskForceSiteCategory = [
  'All',
  'D&I hot spot',
  'New Opportunity site',
]

export const breastSiteCategory = [
  { id: 'Top 30 for TALT', name: 'Breast PICN' },
  { id: 'US TaskForce List', name: 'US TaskForce List' },
  { id: 'Networks', name: 'Networks' },
]

export const breastSiteSubCategory = [
  { id: 'Diversity', name: 'Diversity' },
  { id: 'New Site', name: 'New Site' },
  { id: 'High Deliver', name: 'High Volume' },
  { id: 'Tempus', name: 'Tempus' },
]
