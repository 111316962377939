import React, { useState, memo } from 'react'
import { Table } from 'antd'
import Search from '../Search'
import { SitesTableWrapper } from './SitesTable.styles'

const SitesTable = (props) => {
  const {
    onView = (record) => {
      console.log('view:', record)
    },
  } = props
  const [sortedInfo, setSortedInfo] = useState({})
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 50,
    hideOnSinglePage: true,
  })
  const handleChange = (pagination, filters, sorter) => {
    console.log('Various parameters', pagination, filters, sorter)
    setSortedInfo(sorter)
    setPagination(pagination)
  }

  let columns = [
    {
      title: 'Site Name and Address',
      dataIndex: 'regional_site_name',
      key: 'regional_site_name',
      width: 270,
      sorter: (a, b) =>
        a.regional_site_name.length - b.regional_site_name.length,
      sortOrder:
        sortedInfo.columnKey === 'regional_site_name' && sortedInfo.order,
      render: (regional_site_name, record) => (
        <div>
          <span className="siteName" style={{cursor: 'pointer'}} onClick={() => onView(record)}>
            {regional_site_name}
          </span>
          <br />
          <span className="siteAddress">
            {record.site_address && record.site_address + ', '}
            {record.city}, {record.state}, {record.country}
          </span>
        </div>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      sorter: (a, b) => (a.status|| '').localeCompare(b.status || ''),
      sortOrder: sortedInfo.columnKey === 'status' && sortedInfo.order,
    },
    {
      title: 'PICN Status',
      dataIndex: 'is_approved',
      key: 'is_approved',
      sorter: (a, b) => a.is_approved - b.is_approved,
      sortOrder: sortedInfo.columnKey === 'is_approved' && sortedInfo.order,
      ellipsis: false,
      render: (is_approved) => (
          <span className="siteType">{is_approved ? 'Approved' : ''}</span>
      ),
    },
    {
      title: 'Site Type',
      dataIndex: 'site_type',
      key: 'site_type',
      sorter: (a, b) => a.site_type.length - b.site_type.length,
      sortOrder: sortedInfo.columnKey === 'site_type' && sortedInfo.order,
      ellipsis: false,
      render: (site_type) => (
          <span className="siteType">
            {site_type?.toLowerCase() === 'az site'
              ? 'AZ Site'
              : 'Opportunity Site'}
          </span>
      ),
    },
    {
      title: 'Total Trials',
      dataIndex: 'no_of_study',
      key: 'no_of_study',
      align: 'right',
      sorter: (a, b) => a.no_of_study - b.no_of_study,
      sortOrder: sortedInfo.columnKey === 'no_of_study' && sortedInfo.order,
      render: (no_of_study) => no_of_study || 0,
    },
  ]

  if (props.indicationType?.toLowerCase() === 'breast cancer') {
    columns = columns.concat([
      {
        title: 'Median Rand Rate',
        dataIndex: 'randomization_rate_avg',
        key: 'randomization_rate_avg',
        align: 'right',
        sorter: (a, b) => a.randomization_rate_avg - b.randomization_rate_avg,
        sortOrder:
          sortedInfo.columnKey === 'randomization_rate_avg' && sortedInfo.order,
        render: (randomization_rate_avg) =>
          (+randomization_rate_avg || 0).toFixed(2),
      },
      {
        title: 'Median SSU',
        dataIndex: 'median_ssu',
        key: 'median_ssu',
        align: 'right',
        sorter: (a, b) => a.median_ssu - b.median_ssu,
        sortOrder: sortedInfo.columnKey === 'median_ssu' && sortedInfo.order,
        render: (median_ssu) => (+median_ssu || 0).toFixed(2),
      },
      {
        title: 'Weighted Score',
        dataIndex: 'weighted_score',
        key: 'weighted_score',
        align: 'right',
        sorter: (a, b) => a.weighted_score - b.weighted_score,
        sortOrder:
          sortedInfo.columnKey === 'weighted_score' && sortedInfo.order,
        render: (weighted_score) => (+weighted_score || 0).toFixed(2),
      },
    ])
  }

  return (
    <SitesTableWrapper>
      <div className="siteTableHeader">
        <div className="siteTableTitle">
          List of Sites({props.data?.length || 0})
        </div>
        <div>
          <Search data={[...(props.data || [])]} />
        </div>
        <div className="siteTableDataSource">
          Intel source(s) - Merlin, Symphony, IQVIA
        </div>
      </div>
      <Table
        pagination={pagination}
        columns={columns}
        dataSource={props.data}
        onChange={handleChange}
        sticky
      />
    </SitesTableWrapper>
  )
}

export default memo(SitesTable)
