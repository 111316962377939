import styled from 'styled-components'

export const HeaderWrapper = styled.div`
  .header {
    display: flex;
    background-color: #ffffff;
    border-bottom: 1px solid #0000000a;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    z-index: 1000;
    width: 100%;
    box-shadow: 0px 2px 6px #0000000a;
    > div {
      width: 30%;
    }
  }

  .flex {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .ant-layout-header {
    line-height: unset;
  }
  .menu-item > a, menu-item a:hover{
    background: #e2ebfe;
    border: 1px solid #265ae2!important;
    border-radius: 28px;
    font-size: 14px;
    font-weight: 700;
}
.header-left-container{
  display:flex;  
  align-items: center;
.ant-menu{
  margin-left:40px;
}
}
.ant-menu-horizontal {
  border:0px;
  
}
.ant-menu-horizontal > .ant-menu-item, .ant-menu-horizontal > .ant-menu-submenu {padding: 7px 20px;
  background: #fff;
  border: 2px solid #fff ;
  border-radius: 28px;
    
  margin-right: 10px;}
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected{
border:2px solid rgb(131,0,81);
background:rgb(131,0,81,0.2);
a{
  color:rgb(131,0,81);
  font-weight:700;
  font-size: 14px;

}

  }
.ant-menu-item-selected{

}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected::after{
  border:0px;
}
.ant-menu-horizontal > .ant-menu-item::after, .ant-menu-horizontal > .ant-menu-submenu::after{
  border-bottom:0px !important;
}

`
