import styled from 'styled-components'

export const SiteDetailsWrapper = styled.div`
  width: 100%;

  .link {
    color: #8a0051;
    font-weight: 500;
    margin-bottom: 16px;
    display: block;
    text-decoration: underline;
  }

  .align-right {
    float: right;
  }
`

export const SiteCardWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  border-radius: 12px;
  padding: 10px;
  padding-top: 20px;
  justify-content: space-between;

  &.loading {
    flex-direction: column;
  }
  .sunburst-tooltip {
    max-width: fit-content !important;
  }
`
export const CardWrapper = styled.div`
  width: 100%;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 5px 3px 5px #00000029;
  border-radius: 12px;
  padding: 20px;
  margin-bottom: 16px;
`
