import styled from 'styled-components'

export const DashboardWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const WorldBubbleMap = styled.div`
  display: flex;
  width: 100%;
  height: 500px;
`

export const MapHeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  margin: 0px 5px 20px;
  width: 100%;
  .title {
    color: #868686;
  }
`
export const MapTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  flex: 1;
  .title {
    font-size: 20px;
    color: #43425d;
    font-weight: bold;
    margin-bottom: 5px;
    max-width: 400px;
  }
  .instructions {
    font-size: 12px;
    color: #4d4f5cb3;
    max-width: 400px;
  }
`
export const MapFilterWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  margin: 0px 0px 0px 10px;
`
export const MapFilterWrapper1 = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  width: fit-content;
  margin: 10px 0px 0px 10px;
`

export const MapFilterViewWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  flex: 1;
  flex-direction: column;
`
export const MapFilterCenterWrapper1 = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  margin: 0px 10px 20px;
  .ant-radio-button-wrapper:first-child {
    border-left: 1px solid #8a0051;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }
  .ant-radio-button-wrapper:last-child {
    border-right: 1px solid #8a0051;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }
  .ant-radio-button-wrapper {
    border: 1px solid #8a0051;
    border-top-width: 1px;
    border-left-width: 1px;
    background: #f7f7f7;
    outline: none !important;
    height: auto;
    min-width: 100px;
    padding: 5px 8px;
    text-align: center;
  }
  .ant-radio-group {
    box-shadow: 5px 3px 5px #00000029;
    border: 0px solid #f1f1f1;
    border-radius: 5px;
  }
  .ant-radio-button-wrapper-checked:focus {
    outline: none !important;
  }

  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):first-child {
    border-color: #830051;
  }

  .ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child {
    border-right-color: #830051;
  }

  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    z-index: 1;
    color: #830051;
    background: #fff;
    border-color: #830051;
    &:focus-within {
      box-shadow: none;
    }
  }

  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
    background-color: #830051;
  }

  .ant-radio-button-wrapper-checked:focus {
    outline: none !important;
  }

  @media screen and (max-width: 1180px) {
    .ant-radio-button-wrapper {
      font-size: 10px;
      min-width: unset;
    }
  }
`
export const MapFilterCenterWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  margin: 0px 10px 0px;
  .ant-radio-group {
    box-shadow: 5px 3px 5px #00000029;
    border: 0px solid #f1f1f1;
    border-radius: 5px;
  }
  .ant-radio-button-wrapper:first-child {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border: 1px solid #f1f1f1;
  }

  .ant-radio-button-wrapper:last-child {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border: 1px solid #f1f1f1;
  }
  .ant-radio-button-wrapper {
    outline: none !important;
    border: 1px solid #f1f1f1;
    height: auto;
    min-width: 100px;
    background: #f1f1f1;
    padding: 5px 8px;
    text-align: center;
    border-color: #f7f7f7 !important;
    border-bottom: 3px solid #f1f1f1 !important;
  }
  .ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child {
    border-right-color: #999999;
  }
  .ant-radio-button-wrapper-checked:focus {
    outline: none !important;
  }
  .ant-radio-button-wrapper:not(:first-child)::before {
    background-color: #999999;
  }
  .ant-radio-button-wrapper-checked {
    border-bottom: 3px solid #830051 !important;
    &:focus-within {
      box-shadow: none;
    }
  }

  @media screen and (max-width: 1180px) {
    .ant-radio-button-wrapper {
      font-size: 10px;
      min-width: unset;
    }
  }
`
export const MapCenterWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex: 1;
  margin: 0px 10px 0px;
  padding-top: 20px;
`
