import { useCallback, useEffect, useState, memo } from "react";
import { Empty, Spin, Skeleton } from "antd";
import { KeeOverviewWrapper, OverviewHeader, List, ListHeader, ListContent, ListItem } from "./KeeOverview.styles";
import CONSTANTS from "../../constants";
import { GetAPIRequest } from "../../services";

const getMajorSource = (sources) => {
    let majorSource = sources?.[0];

    sources?.forEach(source => {
        if (source.percent > majorSource.percent) {
            majorSource = source;
        }
    });

    return majorSource.name && `${majorSource.percent}% ${majorSource.name} ($${majorSource.sum.toLocaleString()})`;
};

const KeeOverview = ({ keeId }) => {
    const [keeCollaborations, setKeeCollaborations] = useState({ loading: false, data: [], error: null });

    const getKeeCollaborations = useCallback((keeId) => {
        setKeeCollaborations({ loading: true, data: [], error: null });
        GetAPIRequest({
            url: `${CONSTANTS.api.getKeeCollaborations}/${keeId}`,
        })
            .then((res) => {
                setKeeCollaborations({ loading: false, data: res, error: null });
            }).catch(error => {
                setKeeCollaborations({ loading: false, data: [], error: error.message });
            });
    }, []);

    useEffect(() => {
        if (keeId) {
            getKeeCollaborations(keeId);
        }
    }, [getKeeCollaborations, keeId]);

    const { loading, data } = keeCollaborations;

    return (
        <KeeOverviewWrapper>
            <OverviewHeader>
                <div className="title">Company Collaborations</div>
                <div className="sub-title">Investigator Payments Sunshine Act</div>
            </OverviewHeader>
            <List>
                <ListHeader>
                    <div className="name">Institution Name</div>
                    <div className="persentage">% of total income</div>
                    <div className="major-source">Major Source</div>
                </ListHeader>
                {loading && <Spin />}
                {loading && <Skeleton active />}
                {!loading &&
                    <ListContent>
                        {data.length > 0 ? data.map((item, index) => (
                            <ListItem key={index}>
                                <div className={item.company_name === 'AstraZeneca' ? 'primary_name' : 'name'}>{item.company_name}</div>
                                <div className="persentage">{`${item.percent_from_total}% ($${item.company_amount.toLocaleString()})`}</div>
                                <div className="major-source" style={{ fontWeight: item.company_name === 'AstraZeneca' ? 'bold' : 'normal' }}>{getMajorSource(item.details)}</div>
                            </ListItem>
                        )) : <Empty description="No data available" />
                        }
                    </ListContent>
                }
            </List>
        </KeeOverviewWrapper>
    );
};

export default memo(KeeOverview);
