import { memo } from 'react'
import { FooterWrapper } from './Footer.styles'
import { Footer } from 'antd/lib/layout/layout'
import AZLOGO from '../../img/logo-az-white.png'

const AZFooter = () => (
  <FooterWrapper>
    <Footer className="footer">
      <span>
        <img className="brandLogo" width={120} src={AZLOGO} alt="" />
      </span>
      <div className="footer-links">
        <a href="https://veeva.link/" target="_blank" rel="noreferrer">
          Veeva
        </a>
        <a
          href="https://ofradvancedanalytics.astrazeneca.net/"
          target="_blank"
          rel="noreferrer"
        >
          Merlin
        </a>
        <a
          href="https://control-tower.astrazeneca.com/"
          target="_blank"
          rel="noreferrer"
        >
          Control Tower
        </a>
      </div>
      <span>© Astrazeneca 2022</span>
    </Footer>
  </FooterWrapper>
)

export default memo(AZFooter)
