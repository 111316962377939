import React from 'react'
import { Divider } from 'antd'
import AZLOGO from '../../img/logo-az-condensed.png'
import PICLOGO from '../../img/pic-logo.png'
import { BrandWrapper } from './Brand.styles'
import { NavLink } from 'react-router-dom'

const Brand = () => (
  <BrandWrapper>
    <NavLink to="/">
      <img className="brandLogo" src={AZLOGO} alt="" />
      <Divider className="brandDivider" type="vertical" />
      <img className="productLogo" src={PICLOGO} alt="" />
    </NavLink>
  </BrandWrapper>
)

export default React.memo(Brand)
