import styled from 'styled-components'

export const KeeOverviewWrapper = styled.div`
  .ant-spin-spinning {
    width: 100%;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`

export const OverviewHeader = styled.div`
  padding: 24px;

  .title {
    font-size: 18px;
    font-weight: 500;
    color: #8a0051;
  }

  .sub-title {
  }
`

export const List = styled.div`
  font-size: 0.8rem;
`

export const ListHeader = styled.div`
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  color: #fff;
  background-color: #43425d;
  padding: 8px 24px;
  margin-top: 1rem;

  .name {
    width: 20%;
  }

  .persentage {
    width: 20%;
  }

  .major-source {
    width: 30%;
  }
`

export const ListContent = styled.div``

export const ListItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 24px;

  .primary_name {
    width: 20%;
    font-weight: bold;
    color: #8a0051;
  }

  .name {
    width: 20%;
    font-weight: 600;
  }

  .persentage {
    width: 20%;
    font-weight: 600;
    color: #7cb54a;
  }

  .major-source {
    width: 30%;
  }

  .view-button {
    background: #43425d;
    color: #fff;
    height: 36px;
    font-size: 12px;
    padding: 8px 24px;
    border-radius: 24px;
  }

  .center {
    text-align: center;
  }
`
