import { Skeleton, Spin } from "antd";
import React, { useState, useEffect } from "react";
import SiteList from "../../components/SiteList";
import ToggleSwitch from "../../components/ToggleSwitch";
import SegmentedButton from "../../components/SegmentedButton";
import WorldMap from "../../components/WorldMap";
import {
  DashboardWrapper,
  MapFilterWrapper,
  MapHeaderWrapper,
  MapTitleWrapper,
  MapFilterViewWrapper,
  MapFilterWrapper1,
  MapFilterCenterWrapper,
  MapFilterCenterWrapper1,
  MapCenterWrapper,
} from "./Dashboard.styles";
import Emitter from "../../utils/emitter";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getSites, selectSite } from "../../store/siteSlice";
import Filter from "../../components/Filter";
import {
  indicationTypes,
  top30SiteCategory,
  breastNetworkSiteCategory,
  breastTaskForceSiteCategory,
  breastSiteCategory,
  breastSiteSubCategory,
  filterTypes,
  siteProfileMenu,
  siteTypeMenu,
  siteDiversityMenu
} from "./filterOptions";
import {
  getSelectedIndication,
  selectCountry,
  selectCountryCode,
  selectIndication,
  selectFilterCategory,
  selectSiteCategory,
  selectSiteProfile
} from "../../store/filterSlice";
import { groupSiteData, resetSessionData, setSessionData } from "./utils";

const Dashboard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const sitesData = useSelector(getSites);
  const selectedIndication = useSelector(getSelectedIndication);
  const selectedCountry = sessionStorage.getItem("selectedCountry");
  const selectedFilterCategory = sessionStorage.getItem("selFilterCategory");
  const selectedSiteProfile = sessionStorage.getItem("selSiteProfile");
  const [filterTypeMenu, setFilterTypeMenu] = useState(filterTypes);

  const [
    selectedBreastNetworkSiteCategory,
    setSelectedBreastNetworkSiteCategory,
  ] = useState(sessionStorage.getItem("selBreastNetworkSiteCategory"));
  const [
    selectedBreastTaskForceSiteCategory,
    setSelectedBreastTaskForceSiteCategory,
  ] = useState(sessionStorage.getItem("selBreastTaskForceSiteCategory"));
  const [selectedTop30SiteCategory, setSelectedTop30SiteCategory] = useState(sessionStorage.getItem("selTop30SiteCategory"));
  const [selectedBreastSiteCategory, setSelectedBreastSiteCategory] = useState(sessionStorage.getItem("selBreastSiteCategory") || "Top 30 for TALT");
  const selBreastSiteSubCategoryData = sessionStorage.getItem("selBreastSiteSubCategory")?.split(',') || [];
  const [
    selectedBreastSiteSubCategory,
    setSelectedBreastSiteSubCategory,
  ] = useState(selBreastSiteSubCategoryData[0] ? selBreastSiteSubCategoryData : "All");
  const [selectedSiteType, setSelectedSiteType] = useState(sessionStorage.getItem("selSiteType"));
  const [selectedDiversity, setSelectedDiversity] = useState(sessionStorage.getItem("selDiversity"));
  const [showBreastSiteCategory, setShowBreastSiteCategory] = useState(false);
  const [showSiteProfileCategory, setShowSiteProfileCategory] = useState(false);
  const [showSiteTypeCategory, setShowSiteTypeCategory] = useState(false);
  const [showDiversityCategory, setShowDiversityCategory] = useState(false);
  const [showBreastSiteSubCategory, setShowBreastSiteSubCategory] = useState(false);
  const [
    showBreastTop30SiteCategory,
    setShowBreastTop30SiteCategory,
  ] = useState(false);
  const [showSiteFilter, setShowSiteFilter] = useState(false);
  const [showSiteCategory, setShowSiteCategory] = useState(false);
  const [showFilterCategory, setShowFilterCategory] = useState(false);
  const [
    showBreastNetworkSiteCategory,
    setShowBreastNetworkSiteCategory,
  ] = useState(false);
  const [
    showBreastTaskForceSiteCategory,
    setShowBreastTaskForceSiteCategory,
  ] = useState(false);
  const [mapData, setMapData] = useState([]);
  const [siteData, setSiteData] = useState([]);
  const [stateDataArr, setStateDataArr] = useState([]);
  const [filteredSiteData, setFilteredSiteData] = useState([]);
  const [showSites, setShowSites] = useState(false);
  const [isHome, setIsHome] = useState(true);
  const [title, setTitle] = useState("Partners In Care Sites");
  const [isLoading, setIsLoading] = useState(true);
  const [indications, setIndications] = useState(indicationTypes);

  const bindSites = (res) => {
    const { data, stateData, siteMapData } = groupSiteData(res);
    setSiteData(data);
    setStateDataArr(stateData);
    setMapData(siteMapData);
    setIsLoading(false);
  };

  const onCountrySelect = (country_code) => {
    setShowSiteFilter(false);
    setShowSiteCategory(false);
    setTimeout(() => {
      const selIndication = sessionStorage.getItem("selIndication");
      let sSites = siteData;
      sSites = sSites.filter(
        (p) =>
          p.country_code === country_code &&
          p.indication_type
            ?.toLowerCase()
            .includes(
              indications
                .find((indication) => indication.id == selIndication)
                ?.name.toLowerCase()
            )
      );
      console.log("onCountrySelect", sSites);
      setFilteredSiteData(sSites);
      setShowSites(true);
      dispatch(selectCountry(sSites[0].country));
      dispatch(selectCountryCode(country_code));
      if (!sessionStorage.getItem("selCategory")) {
        setSessionData(sSites[0].country, country_code);
        if (country_code === "US") {
          sessionStorage.setItem("selBreastSiteCategory", "Top 30 for TALT");
        }
      }
      setShowBreastNetworkSiteCategory(false);
      setShowBreastTaskForceSiteCategory(false);
      setShowSiteCategory(false);
      setShowFilterCategory(true);
      if (!sessionStorage.getItem("selFilterCategory")) {
        dispatch(selectFilterCategory("Site Profile"));
        sessionStorage.setItem("selFilterCategory", "Site Profile");
      }

      if (!sessionStorage.getItem("selSiteProfile")) {
        sessionStorage.setItem("selSiteProfile", "All");
        dispatch(selectSiteProfile("All"));
      }

      if (selIndication == 2) {
        if (!sessionStorage.getItem("selBreastSiteCategory") && country_code === "US") {
          sessionStorage.setItem("selBreastSiteCategory", "Top 30 for TALT");
        }
        setTitle(`Breast Cancer Partners In Care Sites, ${sSites[0].country}`);
        if (country_code === "US") {
          setShowBreastSiteSubCategory(true);
          setShowBreastSiteCategory(true);
        }
      } else if (selIndication == 1) {
        setTitle(`Lung Cancer Partners In Care Sites, ${sSites[0].country}`);
        setShowBreastSiteSubCategory(false);
        setShowBreastSiteCategory(false);
      } else {
        setTitle(`Partners In Care Sites, ${sSites[0].country}`);
      }
      let filterMenu = [];
      let siteProfileArr = sSites.filter((p) => {
        console.log("ssites", p.profile_type);
        return (
          (p.profile_type &&
            p.profile_type?.toLowerCase().includes("academic")) ||
          p.profile_type?.toLowerCase().includes("community")
        );
      });
      if (siteProfileArr && siteProfileArr.length > 0) {
        filterMenu.push("Site Profile");

        if (!sessionStorage.getItem("selFilterCategory")) {
          dispatch(selectFilterCategory("Site Profile"));
          sessionStorage.setItem("selFilterCategory", "Site Profile");
          sessionStorage.setItem("selSiteProfile", "All");
          dispatch(selectSiteProfile("All"));
        }

        if (sessionStorage.getItem("selFilterCategory") === "Site Profile") {
          setShowSiteProfileCategory(true);
        }
      } else {
        if (sessionStorage.getItem("selFilterCategory") == "Site Profile") {
          sessionStorage.removeItem("selFilterCategory")
        }
        setShowSiteProfileCategory(false);
      }
      let siteTypeArr = (sSites = sSites.filter(
        (p) =>
          p.site_type &&
          (p.site_type == "AZ Site" || p.site_type == "Opportunity Site")
      ));
      if (siteTypeArr && siteTypeArr.length > 0) {
        filterMenu.push("Site Type");
        if (!sessionStorage.getItem("selFilterCategory")) {
          dispatch(selectFilterCategory("Site Type"));
          sessionStorage.setItem("selFilterCategory", "Site Type");
          sessionStorage.setItem("selSiteType", "All");
          setSelectedSiteType("All");
        }

        if (sessionStorage.getItem("selFilterCategory") === "Site Type") {
          setShowSiteTypeCategory(true);
        } else {
          setShowSiteTypeCategory(false);
        }
      } else {
        if (sessionStorage.getItem("selFilterCategory") == "Site Type") {
          sessionStorage.removeItem("selFilterCategory")
        }
        setShowSiteTypeCategory(false);
      }

      let diversityArr = sSites.filter(
        (p) =>
          (p.diversity_index &&
            (p.diversity_index?.toLowerCase().includes("high") ||
              p.diversity_index?.toLowerCase().includes("medium") ||
              p.diversity_index?.toLowerCase().includes("low"))) ||
          (p.diversity &&
            (p.diversity?.toLowerCase().includes("yes") ||
              p.diversity?.toLowerCase().includes("no")))
      );
      if (diversityArr && diversityArr.length > 0) {
        filterMenu.push("Diversity");

        if (!sessionStorage.getItem("selFilterCategory")) {
          dispatch(selectFilterCategory("Diversity"));
          sessionStorage.setItem("selFilterCategory", "Diversity");
          sessionStorage.setItem("selDiversity", "All");
          setSelectedDiversity("All");
        }

        if (sessionStorage.getItem("selFilterCategory") === "Diversity") {
          setShowDiversityCategory(true);
        } else {
          setShowDiversityCategory(false);
        }
      } else {
        setShowDiversityCategory(false);
      }

      if (country_code === "US") {
        if (sessionStorage.getItem("selBreastSiteCategory") === "Networks") {
          setShowFilterCategory(false);
          setShowSiteProfileCategory(false);
          setShowSiteTypeCategory(false);
          setShowDiversityCategory(false);
          setShowBreastNetworkSiteCategory(true);
        } else {
          if (selIndication == 2 && sessionStorage.getItem("selBreastSiteCategory") === "Top 30 for TALT") {
            setShowBreastSiteSubCategory(true);
          }
        }
      }
      setFilterTypeMenu(filterMenu);
      setTimeout(() => {
        onValueChange("indication");
      }, 100);

      setIsHome(false);
    });
  };

  const viewDetailsClickHandler = (data) => {
    dispatch(selectSite(data));
    navigate(`site/${data.site_info_id}`);
  };

  const onSiteSelect = (state) => {
    if (state.site_info_id) {
      viewDetailsClickHandler(state);
      return;
    }
    setShowSiteFilter(true);
    setShowSiteCategory(true);
    setShowSites(true);
    let sSites = siteData.filter((p) => p.state === state);
    setFilteredSiteData(sSites);
    dispatch(selectCountry(sSites[0].country));
  };

  const onMapBack = () => {
    setShowSiteFilter(false);
    setShowSiteCategory(false);
    setShowSites(false);
    setFilteredSiteData([]);
    dispatch(selectCountry(""));
    dispatch(selectCountryCode(""));
    setShowBreastTop30SiteCategory(false);
    setShowBreastSiteCategory(false);
    setShowBreastSiteSubCategory(false);
    setShowBreastNetworkSiteCategory(false);
    setShowBreastTaskForceSiteCategory(false);
    setShowFilterCategory(false);
    setShowSiteProfileCategory(false);
    setShowSiteTypeCategory(false);
    setShowDiversityCategory(false);
    setTitle("Partners In Care Sites");
    setSelectedSiteType("All");
    dispatch(selectSiteCategory("All"));
    setSelectedTop30SiteCategory("All");
    setSelectedBreastNetworkSiteCategory("All");
    setSelectedBreastTaskForceSiteCategory("All");
    setIsHome(true);

    resetSessionData();
  };

  const onStateMapBack = () => {
    setShowSites(true);
  };

  const onValueChange = (type) => {
    var sSites = siteData;
    var selBreastSiteCategory = sessionStorage.getItem("selBreastSiteCategory");
    var selIndication = sessionStorage.getItem("selIndication");
    var selFilterCategory = sessionStorage.getItem("selFilterCategory");
    var selectedCountryCode = sessionStorage.getItem("selectedCountryCode");

    if (selIndication == 1) {
      sSites = sSites.filter(
        (p) =>
          (p.country_code == selectedCountryCode || selectedCountryCode == null) &&
          p.indication_type?.toLowerCase().includes("lung cancer")
      );

      console.log("ssites-lung-1", selectedCountryCode);
      if (selFilterCategory) {
        var sSites1 = sSites;
        if (selFilterCategory == "Site Profile") {
          var selSiteProfile = sessionStorage.getItem("selSiteProfile");;
          console.log("ssites-1", selSiteProfile);
          if (selSiteProfile) {
            if (selSiteProfile !== "All") {
              sSites1 = sSites.filter(
                (p) =>
                  p.profile_type &&
                  p.profile_type
                    ?.toLowerCase()
                    .includes(selSiteProfile?.toLowerCase())
              );
            } else {
              sSites1 = sSites.filter((p) => {
                console.log("ssites", p.profile_type);
                return (
                  (p.profile_type &&
                    p.profile_type?.toLowerCase().includes("academic")) ||
                  p.profile_type?.toLowerCase().includes("community")
                );
              });
            }

            if (sSites1.length === 0) {
              sessionStorage.setItem("selFilterCategory", "Site Type");
              selFilterCategory = sessionStorage.getItem("selFilterCategory");
              sessionStorage.setItem("selSiteType", "All");
              setShowSiteProfileCategory(false);
            } else {
              setShowSiteProfileCategory(true);
            }
          }
        }
        if (selFilterCategory == "Site Type") {
          var selSiteType = sessionStorage.getItem("selSiteType");
          if (selSiteType) {
            if (selSiteType == "All") {
              sSites1 = sSites.filter(
                (p) =>
                  p.site_type &&
                  (p.site_type == "AZ Site" ||
                    p.site_type == "Opportunity Site")
              );

              if (sSites1.length === 0) {
                sessionStorage.setItem("selFilterCategory", "Diversity");
                selFilterCategory = sessionStorage.getItem("selFilterCategory");
                sessionStorage.setItem("selDiversity", "All");
                setShowSiteTypeCategory(false);
              } else {
                setShowSiteTypeCategory(true);
              }
            } else {
              sSites1 = sSites.filter((p) => {
                return (
                  p.site_type &&
                  p.site_type?.toLowerCase().includes(selSiteType.toLowerCase())
                );
              });
            }
          }
        }
        if (selFilterCategory == "Diversity") {
          var selDiversity = sessionStorage.getItem("selDiversity");
          if (selDiversity) {
            if (selDiversity == "High") {
              sSites1 = sSites.filter(
                (p) =>
                  (p.diversity_index &&
                    (p.diversity_index?.toLowerCase().includes("high") ||
                      p.diversity_index?.toLowerCase().includes("medium"))) ||
                  (p.diversity && p.diversity?.toLowerCase().includes("yes"))
              );
            } else if (selDiversity == "Low") {
              sSites1 = sSites.filter(
                (p) =>
                  (p.diversity_index &&
                    p.diversity_index?.toLowerCase().includes("low")) ||
                  (p.diversity && p.diversity?.toLowerCase().includes("no"))
              );
            } else if (selDiversity == "All") {
              sSites1 = sSites.filter(
                (p) =>
                  (p.diversity_index &&
                    (p.diversity_index?.toLowerCase().includes("high") ||
                      p.diversity_index?.toLowerCase().includes("medium") ||
                      p.diversity_index?.toLowerCase().includes("low"))) ||
                  (p.diversity &&
                    (p.diversity?.toLowerCase().includes("yes") ||
                      p.diversity?.toLowerCase().includes("no")))
              );

              if (sSites1.length === 0) {
                sessionStorage.setItem("selFilterCategory", "");
                setShowDiversityCategory(false);
              } else {
                setShowDiversityCategory(true);
              }
            }
          }
        }

        sSites = sSites1;
      }
    } else if (selIndication == 2) {
      var selectedCountryCode = sessionStorage.getItem("selectedCountryCode");
      sSites = sSites.filter(
        (p) =>
          (p.country_code == selectedCountryCode || selectedCountryCode == null) &&
          p.indication_type?.toLowerCase().includes("breast cancer")
      );

      console.log("ssites", sSites.length);
      console.log("ssites", selBreastSiteCategory);
      if (selectedCountryCode === "US") {
        if (selBreastSiteCategory) {
          let _selBreastSiteCategory = selBreastSiteCategory?.toLowerCase();
          if (_selBreastSiteCategory == "top 30 for talt") {
            sSites = sSites.filter((p) => {
              if (p.categories) {
                if (p.categories.length > 0) {
                  var catArr = p.categories.filter(
                    (c) =>
                      c.category_name &&
                      c.category_name?.toLowerCase() == "top 30 for talt"
                  );
                  return catArr.length > 0;
                } else {
                  return false;
                }
              } else {
                return p.category?.toLowerCase() == "top 30 for talt";
              }
            });
            var selBreastSiteSubCategory = sessionStorage.getItem(
              "selBreastSiteSubCategory"
            );
            if (selBreastSiteSubCategory && selBreastSiteSubCategory != "All") {
              selBreastSiteSubCategory = selBreastSiteSubCategory?.toLowerCase();
              console.log(
                "selBreastSiteSubCategory",
                selBreastSiteSubCategory[0]
              );
              var tempusSites = [];
              var dSites = [];
              var nSites = [];
              var hSites = [];
              if (selBreastSiteSubCategory.includes("tempus")) {
                tempusSites = sSites.filter((p) => p.tempus && p.tempus != "");
              }
              if (selBreastSiteSubCategory.includes("diversity")) {
                dSites = sSites.filter(
                  (p) => p.diversity && p.diversity?.toLowerCase() == "yes"
                );
              }
              if (selBreastSiteSubCategory.includes("new site")) {
                nSites = sSites.filter(
                  (p) =>
                    p.new_site && p.new_site?.toLowerCase().trim() != "az site"
                );
                console.log("selBreastSiteSubCategory", sSites);
              }
              if (selBreastSiteSubCategory.includes("high deliver")) {
                hSites = sSites.filter(
                  (p) => p.high_deliver && p.high_deliver?.toLowerCase() == "y"
                );
              }
              sSites = [...tempusSites, ...dSites, ...nSites, ...hSites];
            }
          } else if (_selBreastSiteCategory == "networks") {
            let selBreastNetworkSiteCategory = sessionStorage.getItem(
              "selBreastNetworkSiteCategory"
            );
            if (selBreastNetworkSiteCategory) {
              if (selBreastNetworkSiteCategory == "All") {
                sSites = sSites.filter((p) => {
                  if (p.categories) {
                    if (p.categories.length > 0) {
                      var catArr = p.categories.filter(
                        (c) =>
                          c.category_name &&
                          c.category_name?.toLowerCase() == "networks"
                      );
                      return catArr.length > 0;
                    } else {
                      return false;
                    }
                  } else {
                    return p.category && p.category?.toLowerCase() == "networks";
                  }
                });
              } else {
                sSites = sSites.filter((p) => {
                  if (p.categories) {
                    if (p.categories.length > 0) {
                      var catArr = p.categories.filter(
                        (c) =>
                          c.category_name &&
                          c.category_name?.toLowerCase() == "networks" &&
                          c.sub_category &&
                          c.sub_category
                            ?.toLowerCase()
                            .includes(selBreastNetworkSiteCategory?.toLowerCase())
                      );
                      return catArr.length > 0;
                    } else {
                      return false;
                    }
                  } else {
                    return (
                      p.category &&
                      p.category?.toLowerCase() == "networks" &&
                      p.sub_category &&
                      p.sub_category
                        ?.toLowerCase()
                        .includes(selBreastNetworkSiteCategory?.toLowerCase())
                    );
                  }
                });
              }
            }

            console.log("ssites--networks", sSites.length);
          } else if (_selBreastSiteCategory == "us taskforce list") {
            sSites = sSites.filter((p) => {
              if (p.categories) {
                if (p.categories.length > 0) {
                  var catArr = p.categories.filter(
                    (c) =>
                      c.category_name &&
                      c.category_name?.toLowerCase() == "us taskforce list"
                  );
                  return catArr.length > 0;
                } else {
                  return false;
                }
              } else {
                return (
                  p.category && p.category?.toLowerCase() == "us taskforce list"
                );
              }
            });
          }
        }
        if (selBreastSiteCategory && selBreastSiteCategory == "Networks") {
        } else {
          if (selFilterCategory) {
            var sSites2 = sSites;
            if (selFilterCategory == "Site Profile") {
              var selSiteProfile = sessionStorage.getItem("selSiteProfile");
              setShowSiteProfileCategory(true);
              console.log("ssites-1", selFilterCategory);
              console.log("ssites-1", selSiteProfile);
              if (selSiteProfile) {
                if (selSiteProfile !== "All") {
                  sSites2 = sSites.filter(
                    (p) =>
                      p.profile_type &&
                      p.profile_type
                        ?.toLowerCase()
                        .includes(selSiteProfile?.toLowerCase())
                  );
                } else {
                  sSites2 = sSites.filter((p) => {
                    console.log("ssites", p.profile_type);
                    return (
                      (p.profile_type &&
                        p.profile_type?.toLowerCase().includes("academic")) ||
                      p.profile_type?.toLowerCase().includes("community")
                    );
                  });

                  if (sSites2.length === 0) {
                    sessionStorage.setItem("selFilterCategory", "Site Type");
                    selFilterCategory = sessionStorage.getItem("selFilterCategory");
                    sessionStorage.setItem("selSiteType", "All");
                    setShowSiteProfileCategory(false);
                  }
                }
              }
            }
            if (selFilterCategory == "Site Type") {
              var selSiteType = sessionStorage.getItem("selSiteType");
              setShowSiteTypeCategory(true);
              if (selSiteType) {
                if (selSiteType == "All") {
                  setSelectedSiteType("All");
                  sSites2 = sSites.filter(
                    (p) =>
                      p.site_type &&
                      (p.site_type == "AZ Site" ||
                        p.site_type == "Opportunity Site")
                  );

                  if (sSites2.length === 0) {
                    sessionStorage.setItem("selFilterCategory", "Diversity");
                    selFilterCategory = sessionStorage.getItem("selFilterCategory");
                    sessionStorage.setItem("selDiversity", "All");
                    setShowSiteTypeCategory(false);
                  } else {
                  }
                } else {
                  sSites2 = sSites.filter((p) => {
                    return (
                      p.site_type &&
                      p.site_type
                        ?.toLowerCase()
                        .includes(selSiteType?.toLowerCase())
                    );
                  });
                }
              }
            }
            if (selFilterCategory == "Diversity") {
              var selDiversity = sessionStorage.getItem("selDiversity");
              if (selDiversity) {
                setShowDiversityCategory(true);
                if (selDiversity == "High") {
                  sSites2 = sSites.filter(
                    (p) =>
                      (p.diversity_index &&
                        (p.diversity_index?.toLowerCase().includes("high") ||
                          p.diversity_index?.toLowerCase().includes("medium"))) ||
                      (p.diversity && p.diversity?.toLowerCase().includes("yes"))
                  );
                } else if (selDiversity == "Low") {
                  sSites2 = sSites.filter(
                    (p) =>
                      (p.diversity_index &&
                        p.diversity_index?.toLowerCase().includes("low")) ||
                      (p.diversity && p.diversity?.toLowerCase().includes("no"))
                  );
                } else if (selDiversity == "All") {
                  sSites2 = sSites.filter(
                    (p) =>
                      (p.diversity_index &&
                        (p.diversity_index?.toLowerCase().includes("high") ||
                          p.diversity_index?.toLowerCase().includes("medium") ||
                          p.diversity_index?.toLowerCase().includes("low"))) ||
                      (p.diversity &&
                        (p.diversity?.toLowerCase().includes("yes") ||
                          p.diversity?.toLowerCase().includes("no")))
                  );

                  if (sSites2.length === 0) {
                    sessionStorage.setItem("selFilterCategory", "");
                    selFilterCategory = sessionStorage.getItem("selFilterCategory");
                    setShowDiversityCategory(false)
                  }
                }
              }
            }

            sSites = sSites2;
          }
        }
      } else {
        if (selFilterCategory) {
          var sSites1 = sSites;
          if (selFilterCategory == "Site Profile") {
            var selSiteProfile = sessionStorage.getItem("selSiteProfile");;
            console.log("ssites-1", selSiteProfile);
            if (selSiteProfile) {
              if (selSiteProfile !== "All") {
                sSites1 = sSites.filter(
                  (p) =>
                    p.profile_type &&
                    p.profile_type
                      ?.toLowerCase()
                      .includes(selSiteProfile?.toLowerCase())
                );
              } else {
                sSites1 = sSites.filter((p) => {
                  console.log("ssites", p.profile_type);
                  return (
                    (p.profile_type &&
                      p.profile_type?.toLowerCase().includes("academic")) ||
                    p.profile_type?.toLowerCase().includes("community")
                  );
                });
              }

              if (sSites1.length === 0) {
                sessionStorage.setItem("selFilterCategory", "Site Type");
                selFilterCategory = sessionStorage.getItem("selFilterCategory");
                sessionStorage.setItem("selSiteType", "All");
                setShowSiteProfileCategory(false);
              } else {
                setShowSiteProfileCategory(true);
              }
            }
          }
          if (selFilterCategory == "Site Type") {
            var selSiteType = sessionStorage.getItem("selSiteType");
            if (selSiteType) {
              if (selSiteType == "All") {
                sSites1 = sSites.filter(
                  (p) =>
                    p.site_type &&
                    (p.site_type == "AZ Site" ||
                      p.site_type == "Opportunity Site")
                );

                if (sSites1.length === 0) {
                  sessionStorage.setItem("selFilterCategory", "Diversity");
                  selFilterCategory = sessionStorage.getItem("selFilterCategory");
                  sessionStorage.setItem("selDiversity", "All");
                  setShowSiteTypeCategory(false);
                } else {
                  setShowSiteTypeCategory(true);
                }
              } else {
                sSites1 = sSites.filter((p) => {
                  return (
                    p.site_type &&
                    p.site_type?.toLowerCase().includes(selSiteType.toLowerCase())
                  );
                });
              }
            }
          }
          if (selFilterCategory == "Diversity") {
            var selDiversity = sessionStorage.getItem("selDiversity");
            if (selDiversity) {
              if (selDiversity == "High") {
                sSites1 = sSites.filter(
                  (p) =>
                    (p.diversity_index &&
                      (p.diversity_index?.toLowerCase().includes("high") ||
                        p.diversity_index?.toLowerCase().includes("medium"))) ||
                    (p.diversity && p.diversity?.toLowerCase().includes("yes"))
                );
              } else if (selDiversity == "Low") {
                sSites1 = sSites.filter(
                  (p) =>
                    (p.diversity_index &&
                      p.diversity_index?.toLowerCase().includes("low")) ||
                    (p.diversity && p.diversity?.toLowerCase().includes("no"))
                );
              } else if (selDiversity == "All") {
                sSites1 = sSites.filter(
                  (p) =>
                    (p.diversity_index &&
                      (p.diversity_index?.toLowerCase().includes("high") ||
                        p.diversity_index?.toLowerCase().includes("medium") ||
                        p.diversity_index?.toLowerCase().includes("low"))) ||
                    (p.diversity &&
                      (p.diversity?.toLowerCase().includes("yes") ||
                        p.diversity?.toLowerCase().includes("no")))
                );

                if (sSites1.length === 0) {
                  sessionStorage.setItem("selFilterCategory", "");
                  setShowDiversityCategory(false);
                } else {
                  setShowDiversityCategory(true);
                }
              }
            }
          }

          sSites = sSites1;
        }
      }
    }

    setFilteredSiteData(sSites);
    var _mapData = [];
    mapData.map((_c) => {
      if (_c.value > 0) {
        var _mc = _c;
        if (selIndication == 0) {
          _mc.value = _c.lungsites + _c.breastcancersites;
          _mapData.push(_mc);
        } else if (selIndication == 1 && _c.lungsites > 0) {
          _mc.value = _c.lungsites;
          _mapData.push(_mc);
        } else if (selIndication == 2 && _c.breastcancersites > 0) {
          _mc.value = _c.breastcancersites;
          _mapData.push(_mc);
        }
      }
    });
    Emitter.emit("UPDATE_DATA", {
      type: type,
      countryData: _mapData,
      siteData: sSites,
    });
  };

  useEffect(() => {
    if (!sitesData.loading && sitesData.data.length > 0) {
      bindSites([...sitesData.data]);
    }
  }, [sitesData]);

  return (
    <DashboardWrapper>
      <MapHeaderWrapper>
        <MapTitleWrapper>
          <div className="title">{title}</div>
          <div className="instructions">
            Hover over a bubble to see quick info. <br />
            Click for more details.
          </div>
        </MapTitleWrapper>
        <MapCenterWrapper>
          {showFilterCategory && (<MapFilterCenterWrapper1>

            <SegmentedButton
              title={""}
              data={filterTypeMenu}
              selectedValue={selectedFilterCategory}
              onChange={(e) => {
                console.log(`selected ${e.target.value}`);
                dispatch(selectFilterCategory(e.target.value));
                sessionStorage.setItem("selFilterCategory", e.target.value);
                if (e.target.value == "Site Profile") {
                  setShowBreastTaskForceSiteCategory(false);
                  sessionStorage.setItem("selSiteProfile", "All");
                  dispatch(selectSiteProfile("All"));
                  setShowBreastTop30SiteCategory(false);
                  setShowBreastNetworkSiteCategory(false);
                  setShowSiteProfileCategory(true);
                  setShowSiteTypeCategory(false);
                  setShowDiversityCategory(false);
                } else if (e.target.value == "Site Type") {
                  sessionStorage.setItem("selSiteType", "All");
                  setSelectedSiteType("All");
                  setShowBreastTaskForceSiteCategory(false);
                  setShowBreastTop30SiteCategory(false);
                  setShowBreastNetworkSiteCategory(false);
                  setShowSiteProfileCategory(false);
                  setShowSiteTypeCategory(true);
                  setShowDiversityCategory(false);
                } else if (e.target.value == "Diversity") {
                  sessionStorage.setItem("selDiversity", "All");
                  setSelectedDiversity("All");
                  setShowBreastTaskForceSiteCategory(false);
                  setShowBreastTop30SiteCategory(false);
                  setShowBreastNetworkSiteCategory(false);
                  setShowSiteProfileCategory(false);
                  setShowSiteTypeCategory(false);
                  setShowDiversityCategory(true);
                }
                onValueChange("indication");
              }}
            />
          </MapFilterCenterWrapper1>
          )}
          <MapFilterCenterWrapper>
            {showSiteProfileCategory && (
              <ToggleSwitch
                title={""}
                data={siteProfileMenu}
                selectedValue={selectedSiteProfile}
                onChange={(e) => {
                  console.log(`selected ${e.target.value}`);
                  dispatch(selectSiteProfile(e.target.value));
                  sessionStorage.setItem("selSiteProfile", e.target.value);
                  onValueChange("indication");
                }}
              />
            )}
            {showSiteTypeCategory && (
              <ToggleSwitch
                title={""}
                data={siteTypeMenu}
                selectedValue={selectedSiteType}
                onChange={(e) => {
                  console.log(`selected ${e.target.value}`);
                  setSelectedSiteType(e.target.value);
                  sessionStorage.setItem("selSiteType", e.target.value);
                  onValueChange("indication");
                }}
              />
            )}
            {showDiversityCategory && (
              <ToggleSwitch
                title={""}
                data={siteDiversityMenu}
                selectedValue={selectedDiversity}
                onChange={(e) => {
                  console.log(`selected ${e.target.value}`);
                  setSelectedDiversity(e.target.value);
                  sessionStorage.setItem("selDiversity", e.target.value);
                  onValueChange("indication");
                }}
              />
            )}
            {showBreastTaskForceSiteCategory && (
              <ToggleSwitch
                title={""}
                data={breastTaskForceSiteCategory}
                selectedValue={selectedBreastTaskForceSiteCategory}
                onChange={(e) => {
                  console.log(`selected ${e.target.value}`);
                  setSelectedBreastTaskForceSiteCategory(e.target.value);
                  sessionStorage.setItem(
                    "selBreastTaskForceSiteCategory",
                    e.target.value
                  );
                  onValueChange("indication");
                }}
              />
            )}
            {showBreastNetworkSiteCategory && (
              <ToggleSwitch
                title={""}
                data={breastNetworkSiteCategory}
                selectedValue={selectedBreastNetworkSiteCategory}
                onChange={(e) => {
                  console.log(`selected ${e.target.value}`);
                  setSelectedBreastNetworkSiteCategory(e.target.value);
                  sessionStorage.setItem(
                    "selBreastNetworkSiteCategory",
                    e.target.value
                  );
                  onValueChange("indication");
                }}
              />
            )}
            {showBreastTop30SiteCategory && (
              <ToggleSwitch
                title={""}
                data={top30SiteCategory}
                selectedValue={selectedTop30SiteCategory}
                onChange={(e) => {
                  console.log(`selected ${e.target.value}`);
                  setSelectedTop30SiteCategory(e.target.value);
                  sessionStorage.setItem(
                    "selTop30SiteCategory",
                    e.target.value
                  );
                  onValueChange("indication");
                }}
              />
            )}
          </MapFilterCenterWrapper>
        </MapCenterWrapper>
        <MapFilterViewWrapper>
          <MapFilterWrapper>
            <Filter
              title={"Indication"}
              placeholder=""
              showSearch={false}
              selectedValue={selectedIndication}
              data={indications}
              onChange={(value) => {
                console.log(`selected ${value}`);
                if (selectedCountry && selectedCountry !== "") {
                  setShowBreastNetworkSiteCategory(false);
                  setShowBreastTaskForceSiteCategory(false);
                  setShowBreastSiteSubCategory(false);
                  setShowBreastTop30SiteCategory(false);
                  setShowSiteCategory(false);
                  setShowFilterCategory(true);
                  setShowBreastSiteCategory(false);

                  dispatch(selectSiteCategory("All"));

                  setShowFilterCategory(true);
                  setShowSiteProfileCategory(true);
                  setShowSiteTypeCategory(false);
                  setShowDiversityCategory(false);
                  sessionStorage.setItem("selFilterCategory", "Site Profile");
                  dispatch(selectFilterCategory("Site Profile"));
                  sessionStorage.setItem(
                    "selBreastSiteCategory",
                    "Top 30 for TALT"
                  );
                  setSelectedBreastSiteCategory("Top 30 for TALT");
                  sessionStorage.setItem(
                    "selBreastTaskForceSiteCategory",
                    "Top 30 for TALT"
                  );
                  sessionStorage.setItem("selBreastNetworkSiteCategory", "All");
                  sessionStorage.setItem("selType", "All");
                  sessionStorage.setItem("selCategory", "All");
                  sessionStorage.setItem("selBreastSiteSubCategory", "");
                  sessionStorage.setItem("selTop30SiteCategory", "All");
                  if (value == 2) {
                    setTitle(
                      `Breast Cancer Partners In Care Sites, ${selectedCountry}`
                    );
                    if (sessionStorage.getItem("selectedCountryCode") === "US") {
                      setShowBreastSiteSubCategory(true);
                      setShowBreastSiteCategory(true);
                    }
                  } else if (value == 1) {
                    setShowBreastSiteSubCategory(false);
                    setTitle(
                      `Lung Cancer Partners In Care Sites, ${selectedCountry}`
                    );
                  } else {
                    setTitle(`Partners In Care Sites, ${selectedCountry}`);
                  }
                } else {
                  sessionStorage.clear();
                  if (value == 2) {
                    setTitle(`Breast Cancer Partners In Care Sites`);
                  } else if (value == 1) {
                    setTitle(`Lung Cancer Partners In Care Sites`);
                  } else {
                    setTitle(`Partners In Care Sites`);
                  }
                }
                dispatch(selectIndication(value));
                sessionStorage.setItem("selIndication", value);
                onValueChange("indication1");
              }}
            />
            {showBreastSiteCategory && (
              <Filter
                title={"Site Category"}
                data={breastSiteCategory}
                placeholder=""
                showSearch={false}
                selectedValue={selectedBreastSiteCategory}
                onChange={(value) => {
                  console.log(`selected ${value}`);
                  setSelectedBreastSiteCategory(value);
                  dispatch(selectFilterCategory("Site Profile"));
                  sessionStorage.setItem("selBreastSiteCategory", value);
                  sessionStorage.setItem("selFilterCategory", "Site Profile");
                  var selCategory = sessionStorage.getItem("selCategory");
                  dispatch(selectSiteCategory(selCategory));
                  if (value == "Top 30 for TALT") {
                    setShowBreastNetworkSiteCategory(false);
                    setShowBreastTaskForceSiteCategory(false);
                    setShowBreastTop30SiteCategory(false);
                    setShowBreastSiteSubCategory(true);
                    setShowSiteCategory(false);
                    setShowFilterCategory(true);
                    setShowSiteProfileCategory(true);
                    setShowSiteTypeCategory(false);
                    setShowDiversityCategory(false);
                  } else if (value == "US TaskForce List") {
                    setShowBreastNetworkSiteCategory(false);
                    setShowBreastTaskForceSiteCategory(false);
                    setShowBreastTop30SiteCategory(false);
                    setShowBreastSiteSubCategory(false);
                    setShowSiteCategory(false);
                    setShowFilterCategory(true);
                    setShowSiteProfileCategory(true);
                    setShowSiteTypeCategory(false);
                    setShowDiversityCategory(false);
                  } else if (value == "Networks") {
                    setShowBreastNetworkSiteCategory(true);
                    setShowBreastTaskForceSiteCategory(false);
                    setShowBreastTop30SiteCategory(false);
                    setShowBreastSiteSubCategory(false);
                    setShowSiteCategory(false);
                    setShowFilterCategory(false);
                    setShowSiteProfileCategory(false);
                    setShowSiteTypeCategory(false);
                    setShowDiversityCategory(false);
                  }
                  onValueChange("indication");
                }}
              />
            )}
          </MapFilterWrapper>
          <MapFilterWrapper1>
            {showBreastSiteSubCategory && (
              <Filter
                mode="multiple"
                allowClear={true}
                title={"Filter by"}
                defaultValue={[]}
                placeholder="Select"
                showSearch={false}
                style={{ width: "300px" }}
                selectedValue={selectedBreastSiteSubCategory}
                data={breastSiteSubCategory}
                onChange={(value) => {
                  console.log(`selected ${value}`);
                  sessionStorage.setItem("selBreastSiteSubCategory", value);
                  setSelectedBreastSiteSubCategory(value);
                  onValueChange("indication");
                }}
              />
            )}
          </MapFilterWrapper1>
        </MapFilterViewWrapper>
      </MapHeaderWrapper>

      {!isLoading ? (
        <WorldMap
          siteData={siteData}
          data={mapData}
          stateData={stateDataArr}
          onCountrySelect={onCountrySelect}
          onStateSelect={() => { }}
          onStateMapBack={onStateMapBack}
          onSiteSelect={onSiteSelect}
          onMapBack={onMapBack}
        />
      ) : (
        <Spin spinning />
      )}
      {isLoading && <Skeleton active />}
      {showSites && (
        <SiteList
          data={filteredSiteData}
          countryName={selectedCountry}
          onSiteSelect={viewDetailsClickHandler}
        />
      )}
    </DashboardWrapper>
  );
};

export default Dashboard;
