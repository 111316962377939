import styled from 'styled-components'

export const ToggleSwitchWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0px 10px;
  .ant-radio-group-solid
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
      border-width: 10px;
    }
  }
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
  }

  .title{
    margin-bottom: 5px;
  }
  .academic-btn{
    font-weight: bold;
    color:#2d75e6;
    
  }
  .usoncology-btn{
    font-weight: bold;
    color:#007580;
    
  }
  .scri-btn{
    font-weight: bold;
    color:#FF8C00;
    
  }
  .new-oppor-btn{
    font-weight: bold;
    color:#CB0003;
    
  }
  .hot-spot-btn{
    font-weight: bold;
    color:#DD5044;
    
  }
  .community-btn{
    font-weight: bold;
    color:#af0062;
    :hover: {
      color:#af0062;
    }
  }
  .all-btn{
    font-weight: bold;
    color: #666666;
  }
  .high-btn{
    font-weight: bold;
    color: #028140;
  }
  .medium-btn{
    font-weight: bold;
    color: #33658a;
  }
  .low-btn{
    font-weight: bold;
    color: #F70000;
  }
`
