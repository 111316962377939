import React, { memo } from 'react'
import { Button, Divider, Spin, Skeleton, Empty } from 'antd'
import {
  SiteCardWrapper,
  SiteImage,
  SiteHeader,
  SiteDetailsWrapper,
  SiteDetails,
  SiteTitle,
  SiteDescription,
  SiteGroup,
  Datafield,
  DataFieldHeader,
  DataFieldValue,
  SiteStudies,
} from './SiteCard.styles'
import defaultImage from '../../img/no-image.png'
import communityIcon from '../../img/community.svg'
import academicIcon from '../../img/academic.svg'
import { isEmpty } from 'lodash'

const getSiteType = (siteType) => {
  if (isNaN(+siteType)) {
    return siteType
  } else {
    return siteType === '1' ? 'AZ Site' : 'Opportunity Site'
  }
}

const SiteCard = ({
  data = {},
  hideViewDetailButton = false,
  showEditButton = false,
  loading = false,
  onSiteSelect = () => {},
  onEdit = () => {},
}) => {
  return (
    <SiteCardWrapper className={`${loading ? 'loading' : ''}`}>
      {loading ? (
        <>
          <Spin />
          <Skeleton active />
        </>
      ) : isEmpty(data) ? (
        <Empty description="Site data unavailable" />
      ) : (
        <>
          <SiteImage src={data.photo || defaultImage}></SiteImage>
          <SiteHeader>
            <SiteTitle>
              {data.regional_site_name?.replace('N/A', 'Unavailable')}
            </SiteTitle>
            <SiteDescription>
              {data.site_address && <div>{data.site_address},</div>}
              <div>
                {data.city && data.city + ', '}
                {data.state && data.state + ', '}
                {data.country_code}
              </div>
              {data.country_code?.toLowerCase() === 'us' && (
                <SiteGroup>
                  <img
                    src={
                      data.profile_type?.toLowerCase() === 'community'
                        ? communityIcon
                        : academicIcon
                    }
                    alt=""
                  />
                  <span className="site-group">
                    {data.profile_type?.replace('N/A', 'Unavailable')}
                  </span>
                </SiteGroup>
              )}
            </SiteDescription>
          </SiteHeader>
          <SiteDetailsWrapper>
            <SiteDetails>
              <Datafield>
                <DataFieldHeader>Indication</DataFieldHeader>
                <DataFieldValue>
                  {data.indication_type?.replace('N/A', 'Unavailable')}
                </DataFieldValue>
              </Datafield>
              {data.indication_type === 'Lung Cancer' && (
                <Datafield>
                  <DataFieldHeader>Site Type</DataFieldHeader>
                  <DataFieldValue>{getSiteType(data.site_type)}</DataFieldValue>
                </Datafield>
              )}
              <Datafield>
                <DataFieldHeader>Total AZ Trials</DataFieldHeader>
                <DataFieldValue>{data.total_trails}</DataFieldValue>
              </Datafield>{' '}
              <Datafield>
                <DataFieldHeader>Total KEE</DataFieldHeader>
                <DataFieldValue>{data.total_kee}</DataFieldValue>
              </Datafield>
              {data.country_code?.toLowerCase() === 'us' && (
                <Datafield>
                  <DataFieldHeader>Diversity Index</DataFieldHeader>
                  <DataFieldValue>
                    {data.diversity_index?.replace('N/A', 'Unavailable')}
                  </DataFieldValue>
                </Datafield>
              )}
            </SiteDetails>
            <Divider />
            <SiteDetails>
              {data.country_code?.toLowerCase() === 'us' && (
                <Datafield>
                  <DataFieldHeader>Patient Volume</DataFieldHeader>
                  <DataFieldValue>{data.patient_volume}</DataFieldValue>
                </Datafield>
              )}
              <Datafield>
                <DataFieldHeader>
                  Standard Contract Language Available
                </DataFieldHeader>
                <DataFieldValue>
                  {data.standard_contract_language_available?.replace(
                    'N/A',
                    'Unavailable',
                  ) || 'Unavailable'}
                </DataFieldValue>
              </Datafield>
              <Datafield>
                <DataFieldHeader>Site status</DataFieldHeader>
                <DataFieldValue>
                  {data.status?.replace('N/A', 'Unavailable')}
                </DataFieldValue>
              </Datafield>

              <Datafield>
                <DataFieldHeader>Early Phase Potential</DataFieldHeader>
                <DataFieldValue>
                  {data.early_phase || 'Unavailable'}
                </DataFieldValue>
              </Datafield>
            </SiteDetails>
            <SiteStudies>
              <div>
                <DataFieldHeader>AZ Studies</DataFieldHeader>
                <DataFieldValue>
                  {(Array.isArray(data.az_studies) &&
                    data.az_studies.map((d, idx) => {
                      return (
                        <Button
                          key={idx}
                          disabled={!d.url}
                          target={'blank'}
                          href={
                            d.url
                              ? `${d.url}/countries/${data.country_code}`
                              : ''
                          }
                          type="link"
                        >
                          {d.study_acronym}
                          {idx < data.az_studies.length - 1 ? ', ' : ''}
                        </Button>
                      )
                    })) ||
                    'Unavailable'}
                </DataFieldValue>
              </div>
              {!hideViewDetailButton && (
                <Button
                  className="view-button"
                  style={{ alignContent: 'flex-end' }}
                  onClick={() => onSiteSelect(data)}
                >
                  View Details
                </Button>
              )}
              {showEditButton && (
                <Button
                  className="view-button"
                  style={{ alignContent: 'flex-end' }}
                  onClick={() => onEdit(data)}
                >
                  Edit
                </Button>
              )}
            </SiteStudies>
          </SiteDetailsWrapper>
        </>
      )}
    </SiteCardWrapper>
  )
}

export default memo(SiteCard)
