import React, { memo } from 'react'
import Dashboard from '../Dashboard/Dashboard'

const Home = () => {
  return (
    <div className="HomeWrapper">
      <Dashboard />
    </div>
  )
}

export default memo(Home)
