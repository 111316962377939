import React, { useCallback, useState, useEffect } from "react";
import {
  Button,
  Modal,
  Form,
  Input,
  Space,
  Popconfirm,
  Layout,
  Table,
  Typography,
  Select,
  Spin,
  message,
} from "antd";

import { UserDetailsWrapper } from "./UserDetails.styles";
import {
  PlusCircleOutlined,
  EditOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
//import useAuth from '../../../hooks/useAuth'
//import {getUsers} from
import { GetAPIRequest } from "../../../services";
import CONSTANTS from "../../../constants";
import { PostAPIRequest } from "../../../services/api";
import useAuth from "../../../hooks/useAuth";
const { Option } = Select;
const { Search } = Input;

const UserDetails = () => {
  const userDetails = useAuth();
  //console.log('user', userDetails)
  const [pridId, setPridId] = useState("");
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("");
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [userRoles, setUserRoles] = useState([]);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(true);
  const [searchString, setSearchString] = useState("");
  const [editingKey, setEditingKey] = useState("");
  const [visible, setVisible] = useState(false);
  const [mode, setMode] = useState("add");

  useEffect(() => {
    getUsers();
    getUsersRoles();
  }, []);
  // console.log("data", data)
  const getUsers = () => {
    setLoading(true);
    GetAPIRequest({
      url: `${CONSTANTS.api.getUsers}`,
    })
      .then((res) => {
        console.log("getUsers", res);
        setData(res.data);
        setFilteredData(res.data);
        setLoading(true);
      })
      .catch((err) => {})
      .finally(() => {
        setLoading(false);
      });
  };
  const getUsersRoles = () => {
    GetAPIRequest({
      url: `${CONSTANTS.api.getUsersRoles}`,
    })
      .then((res) => {
        console.log("getUsersRoles", res.data);
        setUserRoles(res.data);
      })
      .catch((err) => {});
  };
  const columns = [
    {
      title: "Prid ID",
      dataIndex: "prid_id",
      key: "prid_id",
      sorter: (a, b) => a.prid_id.localeCompare(b.prid_id),
    },

    {
      title: "Email ID",
      dataIndex: "email_id",
      key: "email_id",
      sorter: (a, b) => a.email_id.localeCompare(b.email_id),
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
      sorter: (a, b) => a.role.localeCompare(b.role),
    },
    {
      title: "Action",
      width: 150,
      dataIndex: "action",
      // className: userDetails ? '' : 'hide-column',
      render: (_, record) => {
        return (
          <>
            <div className="edit-container">
              <div className="edit-ctn">
                <Space>
                  <Typography.Link
                    // disabled={editingKey !== ""}
                    onClick={() => edit(record)}
                  >
                    <EditOutlined /> Edit
                  </Typography.Link>
                </Space>
              </div>

              <Popconfirm
                title="Sure to delete?"
                onConfirm={() => {
                  handleDelete(record);
                }}
              >
                {" "}
                <div className="delete-ctn">
                  <DeleteOutlined /> delete
                </div>
              </Popconfirm>
            </div>
          </>
        );
      },
    },
  ];
  const showModal = () => {
    setVisible(true);
    setMode("add");
  };
  const edit = (record) => {
    showModal();
    setMode("edit");

    setPridId(record.prid_id);
    setEmail(record.email_id);
    setRole(record.role_id);
    form.setFieldsValue({
      prid_id: record.prid_id,
      email_id: record.email_id,
      role_id: record.role,
    });
  };

  const handleCancel = () => {
    form.resetFields();
    setVisible(false);
    setMode("add");
  };

  const filterData = useCallback(
    (searchString) => {
      const filteredData = data.filter(
        (item) =>
          item.prid_id.toLowerCase().includes(searchString) ||
          item.email_id.toLowerCase().includes(searchString)
      );
      setFilteredData(filteredData);
    },
    [data]
  );
  const onSearch = useCallback((event) => {
    setSearchString((event.target.value || "").toLowerCase());
  }, []);

  useEffect(() => {
    filterData(searchString);
  }, [filterData, searchString]);

  const handleAddUser = () => {
    form.validateFields().then((values) => {
      const userExists = data.some((item) => item.prid_id === values.prid_id);

      if (userExists) {
        message.warning("User already exists!");
      } else {
        const newData = {
          prid_id: values.prid_id,
          email_id: values.email_id,
          role_id: values.role_id,
        };

        PostAPIRequest({
          url: `${CONSTANTS.api.UserSave}`,
          data: newData,
        })
          .then((response) => {
            getUsers();
            message.success("User added successfully!");
          })
          .catch((error) => {
            console.error("Error adding user:", error);
            message.error("Failed to add user.");
          })
          .finally(() => {
            form.resetFields();
            setVisible(false);
          });
      }
    });
  };
  const handleUserupdated = () => {
    form.validateFields().then((values) => {
      const newData = {
        prid_id: values.prid_id,
        email_id: values.email_id,
        role_id: values.role_id,
      };

      PostAPIRequest({
        url: `${CONSTANTS.api.UserSave}`,
        data: newData,
      })
        .then((response) => {
          getUsers();
          message.success("user updated successfully");
        })

        .finally(() => {
          form.resetFields();
          setVisible(false);
        });
    });
  };
  const handleDelete = (record) => {
    const requestData = {
      prid_id: record.prid_id,
    };

    PostAPIRequest({
      url: `${CONSTANTS.api.UserDelete}`,
      data: requestData,
    })
      .then((response) => {
        message.success("User Deleted successfully");
        getUsers();
      })
      .catch((error) => {
        console.error("Error deleting user:", error);
      });
  };
  console.log("mode", mode);
  return (
    <div>
      <UserDetailsWrapper>
        <div className="topheadr">
          <button type="primary" className="addbtn" onClick={showModal}>
            <PlusCircleOutlined />
            Add User
          </button>
          <Search
            placeholder="Search here"
            style={{ width: 200, marginLeft: "10px", marginTop: "2px" }}
            allowClear
            onChange={onSearch}
          />
          <Modal
            title={mode === "add" ? "Add User" : "User Update"}
            visible={visible}
            // onOk={handleAddUser}
            onCancel={handleCancel}
            destroyOnClose
            footer={[
              <Button key="cancel" onClick={handleCancel}>
                Cancel
              </Button>,
              <Button
                key="submit"
                type="primary"
                onClick={mode === "add" ? handleAddUser : handleUserupdated}
              >
                {mode === "add" ? "Add " : "Update"}
              </Button>,
            ]}
          >
            <Form form={form} layout="horizontal">
              <Form.Item
                label="Prid Id"
                name="prid_id"
                rules={[{ required: true }]}
                labelCol={{ span: 4 }}
                wrapperCol={{ span: 8 }}
              >
                <Input placeholder="Prid Id" />
              </Form.Item>
              <Form.Item
                label="Email Id"
                name="email_id"
                rules={[{ required: true }]}
                labelCol={{ span: 4 }}
                wrapperCol={{ span: 8 }}
              >
                <Input placeholder="Email" />
              </Form.Item>
              <Form.Item
                label="Role"
                name="role_id"
                rules={[
                  {
                    required: true,
                    message: "Please input products!",
                  },
                ]}
                labelCol={{ span: 4 }}
                wrapperCol={{ span: 8 }}
              >
                <Select placeholder="Select a role">
                  {userRoles.length > 0 ? (
                    userRoles.map((role) => (
                      <Select.Option key={role.role_name} value={role.role_id}>
                        {" "}
                        {role.role_name}{" "}
                      </Select.Option>
                    ))
                  ) : (
                    <span>Please select filters</span>
                  )}
                </Select>
              </Form.Item>
            </Form>
          </Modal>
        </div>

        <Table
          className="modeltable"
          dataSource={filteredData}
          columns={columns}
          loading={loading}
        />
      </UserDetailsWrapper>
    </div>
  );
};
export default UserDetails;
