import { configureStore } from '@reduxjs/toolkit'
import siteReducer from './siteSlice'
import keeReducer from './keeSlice'
import filterReducer from './filterSlice'
import { siteNetworkApi } from '../services/siteNetwork'
import { sitePatientPapulationApi } from '../services/sitePatientPapulation'

export const store = configureStore({
  reducer: {
    site: siteReducer,
    kee: keeReducer,
    filter: filterReducer,
    [siteNetworkApi.reducerPath]: siteNetworkApi.reducer,
    [sitePatientPapulationApi.reducerPath]: sitePatientPapulationApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(siteNetworkApi.middleware),
})
