import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import CONSTANTS from '../constants'
import { getCookie } from '../utils/cookie-helper'

export const sitePatientPapulationApi = createApi({
  reducerPath: 'sitePatientPapulation',
  baseQuery: fetchBaseQuery({
    prepareHeaders: (headers) => {
      const access_token = getCookie('access-token')

      if (access_token && access_token !== '') {
        headers.set('authorization', 'Bearer ' + access_token)
      }

      return headers
    },
  }),
  endpoints: (builder) => ({
    getSitePatientPopulationByName: builder.query({
      query: ({ siteName, year, type }) => {
        if (!siteName) return ''

        let url = ''

        if (type?.toLowerCase() === 'breast cancer') {
          url += `${CONSTANTS.api.getBCWheelData}?indication=BCsitePatientPapulationApi&sitename=${siteName}&year=${year}`
        } else {
          url += `${CONSTANTS.api.getWheelData}?type=parent`
          url += `&id=` + (siteName || '').toUpperCase()
          url += `&year=2021,2020`
          url += `&profile=NSCLC&`
          url += `&startDate=2019-01-01`
          url += `&endDate=2022-03-07`
          url += `&dataSource=I`
        }

        return url
      },
    }),
  }),
})

export const {
  useGetSitePatientPopulationByNameQuery,
} = sitePatientPapulationApi
