import React, { useEffect, useState, useRef, memo } from 'react'
import { Input, AutoComplete } from 'antd'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { SearchWrapper } from './Search.styles'
import { selectAdminSite } from '../../../store/siteSlice'

const Search = ({ data }) => {
  const inputElement = useRef()
  const [options, setOptions] = useState([])
  const [searchString, setSearchString] = useState('')
  const [searchData, setSearchData] = useState(data)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const viewDetailsClickHandler = (data) => {
    dispatch(selectAdminSite(data))
    setSearchString('')
    navigate(`/admin/site/${data.id}`)
  }

  useEffect(() => {
    setOptions([])
    setSearchString('')
    setSearchData(data)
  }, [data])

  const handleSearch = (value) => {
    var searchRegex = new RegExp(value, 'ig')
    var searchString = value?.toLowerCase()
    var sSites = searchData.filter(
      (p) =>
        p.regional_site_name?.toLowerCase().includes(searchString) ||
        p.city?.toLowerCase().includes(searchString) ||
        p.state?.toLowerCase().includes(searchString) ||
        p.country?.toLowerCase().includes(searchString),
    )
    var opts = []
    sSites.forEach((s) => {
      opts.push({
        value: s.regional_site_name,
        label: (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}
          >
            <span
              style={{ fontSize: 14 }}
              dangerouslySetInnerHTML={{
                __html: s.regional_site_name.replace(
                  searchRegex,
                  (v) => `<b>${v}</b>`,
                ),
              }}
            ></span>
            <span
              style={{ fontSize: 10, textAlign: 'left' }}
              dangerouslySetInnerHTML={{
                __html: `${s.city?.replace(
                  searchRegex,
                  (v) => `<b>${v}</b>`,
                )}, ${s.state?.replace(
                  searchRegex,
                  (v) => `<b>${v}</b>`,
                )}, ${s.country?.replace(searchRegex, (v) => `<b>${v}</b>`)}`,
              }}
            ></span>
          </div>
        ),
      })
    })
    setOptions(opts)
  }

  const onSelect = (value) => {
    const seletedSite = searchData.find((s) => s.regional_site_name === value)

    if (seletedSite) {
      viewDetailsClickHandler(seletedSite)
    }
  }

  const handleChange = (value) => {
    setSearchString(value)
  }

  return (
    <SearchWrapper>
      <AutoComplete
        className="select"
        options={options}
        value={searchString}
        onSelect={onSelect}
        onSearch={handleSearch}
        onChange={handleChange}
        allowClear
        showSearch
      >
        <Input.Search
          ref={inputElement}
          size="large"
          placeholder="Search for a site name"
          value={searchString}
        />
      </AutoComplete>
    </SearchWrapper>
  )
}

export default memo(Search)
