import styled from 'styled-components'

export const KeeListItemWrapper = styled.div`
  border: solid 2px #dedee2;
  border-top-width: 0;
`

export const KeeListItemDetails = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 8px;
    font-size: 0.8rem;
    font-weight: 500;
    background: #fcfcfc;

    &.primary-kee {
        border-left: solid 8px #9d3878;
        margin-left: -2px;
    }

    > div {
        width: 25%;
        padding: 8px 16px;
    }

    .doctor-image {
        display: flex;
        
        img {
            width: 60px;
            height: 60px;
            border-radius: 50%;
            aspect-ratio: 1;
            object-fit: cover;
        }

        .name-wrapper {
            padding: 0 16px;
            color: #8e0957;
        }

        .name {
            font-size: 12px;
            font-weight: bold;
        }

        .caption {
            font-size: 10px;
        }

        .image-placeholder {
            width: 60px;
            height: 60px;
            border-radius: 50%;
            background: #d0d0dd;
            display: flex;
            align-items: center;
            justify-content: center;
            flex: none;
        }
    }
}
`

export const KeeActivities = styled.div`
  font-size: 0.8rem;
  display: flex;
  background: #f5f5f5;
  padding: 16px;
  align-items: center;
  justify-content: space-between;

  .activity-label {
    font-weight: bold;
    text-transform: uppercase;
    margin-left: 93px;
    margin-right: 20px;
  }

  .activity-container {
    display: flex;
    width: 70%;
  }

  .activity {
    margin: 0 20px;
  }

  .activity-name {
    margin: 8px 0;
    font-size: 12px;
  }

  .activity-percentage {
    position: relative;
    background: #dedee2;
    height: 2px;
    .precentage {
      position: absolute;
      top: -2px;
      left: -1px;
      height: 6px;
      background-image: linear-gradient(to right, #d153a1, #636dda);
    }
  }

  .view-button {
    background: #43425d;
    color: #fff;
    height: 40px;
    font-size: 1rem;
    border-radius: 0;
  }
`
