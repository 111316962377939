import React, { memo } from 'react'
import { SegmentedButtonWrapper } from './SegmentedButton.styles'
import { Radio } from 'antd'

function getClassName(item) {
  if (item === 'us oncology') {
    return 'usoncology-btn'
  } else if (item === 'scri') {
    return 'scri-btn'
  } else if (item === 'd&i hot spot') {
    return 'hot-spot-btn'
  } else if (item === 'new opportunity site') {
    return 'scri-btn'
  } else if (item === 'opportunity site') {
    return 'scri-btn'
  } else if (item === 'az site') {
    return 'community-btn'
  } else if (item === 'academic') {
    return 'academic-btn'
  } else if (item === 'community') {
    return 'community-btn'
  } else if (item === 'high') {
    return 'high-btn'
  } else if (item === 'medium') {
    return 'medium-btn'
  } else if (item === 'low') {
    return 'low-btn'
  } else {
    return 'all-btn'
  }
}

const SegmentedButton = (props) => {
  const { title, data, onChange, selectedValue } = props

  return (
    <SegmentedButtonWrapper>
      <div className="title">{title}</div>
      <Radio.Group
        value={selectedValue ? selectedValue : data.length > 0 ? data[0] : ''}
        onChange={onChange}
        buttonStyle="solid"
      >
        {data.map((item, idx) => (
          <Radio.Button
            className={getClassName(item.toLowerCase())}
            key={idx}
            value={item}
          >
            {item}
          </Radio.Button>
        ))}
      </Radio.Group>
    </SegmentedButtonWrapper>
  )
}

export default memo(SegmentedButton)
