import { useCallback, useEffect, useState, memo } from 'react'
import {
  SiteDetailsWrapper,
  SiteCardWrapper,
  CardWrapper,
} from './SiteDetails.styles'
import { useDispatch, useSelector } from 'react-redux'
import { selectSite, getSiteDetails } from '../../store/siteSlice'
import { selectKee } from '../../store/keeSlice'
import SiteCard from '../../components/SiteCard'
import KeeList from '../../components/KeeList'
import { NavLink, useLocation, useNavigate, useParams } from 'react-router-dom'
import CONSTANTS from '../../constants'
import { GetAPIRequest } from '../../services'
import SiteNetworkMap from '../../components/SiteNetworkMap'
import PatientPopulationChart from '../../components/PatientPopulationChart'
import Filter from '../../components/Filter'
import { Tabs } from 'antd'
import { useGetSiteNewworkByNameQuery } from '../../services/siteNetwork'

const { TabPane } = Tabs
const defaultYear = new Date().getFullYear()
let year = defaultYear
const years = []

while (year >= 2007) {
  years.push({ id: year, name: year })
  year -= 1
}

const SiteDetails = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const siteDetails = useSelector(getSiteDetails)
  const [activeTab, setActiveTab] = useState('1')
  const [selectedYear, setSelectedYear] = useState(defaultYear)
  const [patientWheelData, setPatientWheelData] = useState()
  const [siteData, setSiteData] = useState({
    loading: false,
    data: siteDetails,
    error: null,
  })
  const [keeData, setKeeData] = useState({
    loading: false,
    data: [],
    error: null,
  })
  const { id } = useParams()
  const { data: siteNetworkData } = useGetSiteNewworkByNameQuery(
    {
      siteName: siteDetails.regional_site_name,
      year: selectedYear,
    },
    {
      skip: !siteDetails.regional_site_name,
    },
  )

  useEffect(() => {
    if (siteNetworkData?.length > 0) {
      setActiveTab('1')
    } else {
      setActiveTab('2')
    }
  }, [siteNetworkData])

  const handleKeeSelect = (keeDetails) => {
    dispatch(selectKee(keeDetails))
    navigate(`kee/${keeDetails.id}`)
  }

  const fetchKees = useCallback((id) => {
    setKeeData({ loading: true, data: [], error: null })
    GetAPIRequest({
      url: `${CONSTANTS.api.getSiteKees}/${id}`,
    })
      .then((res) => {
        setKeeData({ loading: false, data: res, error: null })
      })
      .catch((error) => {
        setKeeData({ loading: false, data: [], error: error.message })
      })
  }, [])

  const fetchSiteDetails = useCallback(
    (id) => {
      setKeeData({ loading: true, data: [], error: null })
      GetAPIRequest({
        url: `${CONSTANTS.api.getSiteDetails}/${id}`,
      })
        .then((res) => {
          dispatch(selectSite(res))

          setSiteData({ loading: false, data: res, error: null })
        })
        .catch((error) => {
          setSiteData({ loading: false, data: {}, error: error.message })
        })
    },
    [dispatch],
  )

  const handleYearchange = (value) => {
    setSelectedYear(value)
  }

  useEffect(() => {
    if (id) {
      fetchKees(id)
    }
  }, [fetchKees, id])

  useEffect(() => {
    if (!Object.keys(siteDetails).length) {
      setSiteData({ loading: true })
      fetchSiteDetails(id)
    } else {
      setSiteData({ loading: false, data: siteDetails, error: null })
    }
  }, [fetchSiteDetails, id, siteDetails])

  useEffect(() => {
    if (siteDetails && siteDetails.commercial_site_indicator) {
      const accountId = siteDetails.commercial_site_indicator

      if (accountId) {
        let url = ''

        if (siteDetails.indication_type?.toLowerCase() === 'breast cancer') {
          url = `${CONSTANTS.api.getBCWheelData}?indication=BC&sitename=${accountId}&year=${year}`
        } else {
          url = `${CONSTANTS.api.getWheelData}?type=parent`
          url += `&id=` + (accountId || '').toUpperCase()
          url += `&year=2021,2020`
          url += `&profile=NSCLC&`
          url += `&startDate=2019-01-01`
          url += `&endDate=2022-03-07`
          url += `&dataSource=I`
        }

        GetAPIRequest({
          url: url,
        })
          .then((res) => {
            if (res && res.nuuid) {
              setPatientWheelData(res)
            }
          })
          .catch((error) => {
            console.log('Error', error)
            setPatientWheelData(null)
          })
      }
    } else {
      setPatientWheelData(null)
    }
  }, [siteDetails])

  function handleTabChange(key) {
    setActiveTab(key)
  }

  return (
    <SiteDetailsWrapper>
      <NavLink to="/">Back to Sites</NavLink>
      <SiteCard
        data={siteData.data}
        loading={siteData.loading}
        hideViewDetailButton
        showEditButton={location.pathname.includes('admin')}
        onEdit={(data) => navigate('/admin/site')}
      />
      {(siteNetworkData?.length > 0 ||
        patientWheelData?.children?.length > 0) && (
        <CardWrapper>
          <Tabs
            defaultActiveKey="1"
            activeKey={activeTab}
            centered
            onChange={handleTabChange}
          >
            {siteNetworkData?.length && (
              <TabPane tab="Site Network" key="1">
                <div className="align-right">
                  <Filter
                    title={'Year'}
                    placeholder=""
                    showSearch={false}
                    selectedValue={selectedYear}
                    data={years}
                    onChange={handleYearchange}
                  />
                </div>
                <SiteCardWrapper>
                  <SiteNetworkMap
                    data={siteNetworkData}
                    countryCode={siteDetails.country_code}
                  />
                </SiteCardWrapper>
              </TabPane>
            )}
            {patientWheelData?.children?.length && (
              <TabPane tab="Patient Population" key="2">
                <div className="align-right">
                  <Filter
                    title={'Year'}
                    placeholder=""
                    showSearch={false}
                    selectedValue={selectedYear}
                    data={years}
                    onChange={handleYearchange}
                  />
                </div>
                <SiteCardWrapper>
                  <PatientPopulationChart
                    accountId={siteDetails.commercial_site_indicator}
                    data={patientWheelData}
                  />
                </SiteCardWrapper>
              </TabPane>
            )}
          </Tabs>
        </CardWrapper>
      )}
      <KeeList
        keeData={keeData}
        siteName={siteDetails?.regional_site_name}
        onKeeSelect={handleKeeSelect}
      />
    </SiteDetailsWrapper>
  )
}

export default memo(SiteDetails)
