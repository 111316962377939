import styled from 'styled-components'

export const FilterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  margin: 0px 10px;

  .filterTitle {
    font-size: 14px;
    text-align: left;
    width: 100%;
    margin-bottom: 5px;
    color: #868686;
  }
  .selectField {
    width: 140px;
  }
  .ant-select-selection-item {
    color: #4d4f5c;
    font-size: 13px;
  }
`
