import { memo, useEffect, useState } from 'react'
import {
  ChartWrapper,
  LegendContainerWrapper,
} from './PatientPopulationChart.styles'
import { scaleOrdinal } from 'd3-scale'
import { interpolateRainbow } from 'd3-scale-chromatic'
import * as d3 from 'd3'
import Emitter from '../../utils/emitter'
import { useCallback } from 'react'
import { isEmpty } from 'lodash'

window.d3 = d3

const getPercent = (d) => {
  let percent = 0
  if (d.data.size) {
    if (d.parent && d.parent.data && d.parent.data.size) {
      percent = d.data.size / d.parent.data.size
    }
    if (d.parent && d.parent.data && d.parent.data.node_size) {
      percent = d.data.size / d.parent.data.node_size
    }
    percent = percent * 100
  }
  if (d.data.node_size) {
    if (d.parent && d.parent.data && d.parent.data.size) {
      percent = d.data.node_size / d.parent.data.size
    }
    if (d.parent && d.parent.data && d.parent.data.node_size) {
      percent = d.data.node_size / d.parent.data.node_size
    }
    percent = percent * 100
  }
  return percent
}

const GlobalLegend = (props) => {
  let { title, data, count, percent, nodeColors, dataType = 'wheel' } = props
  const onLegendClick = function (e, type) {
    Emitter.emit('LegendClick', { data })
  }

  useEffect(() => {
    console.log('GlobalLegend', GlobalLegend)
  }, [])
  let background = {
    borderLeft: `10px solid ${nodeColors[data.nuuid]}`,
  }
  return (
    <div
      className="sunburst-legend-title"
      data-type={dataType}
      onClick={onLegendClick}
    >
      <span style={background} className="wheel-legend-title-1">
        <span className="sunburst-legend-label">{title}</span>
        <span className="sunburst-legend-total-label1">
          <span className="sunburst-legend-total-count">{count}</span>
          {percent && (
            <span className="sunburst-legend-total-percent">({percent}%)</span>
          )}
        </span>
      </span>
    </div>
  )
}

const ParentLegend = (props) => {
  let {
    title,
    data,
    count,
    parentCount,
    percent,
    nodeColors,
    dataType = 'wheel',
  } = props
  const onLegendClick = function (e, type) {
    Emitter.emit('LegendClick', { data })
  }

  let background = {
    borderLeft: `10px solid ${nodeColors[data.data.nuuid]}`,
  }

  return (
    <div
      className="sunburst-legend-title"
      data-type={dataType}
      onClick={onLegendClick}
    >
      <span style={background} className="wheel-legend-title-1">
        <span className="sunburst-legend-label">{title}</span>
        {parentCount > 1 ? (
          <span className="sunburst-legend-total">
            <span className="sunburst-legend-total-label1">
              <span className="sunburst-legend-total-count">{count || 0}</span>
              {percent && (
                <span className="sunburst-legend-total-percent">
                  ({percent}%)
                </span>
              )}
            </span>
          </span>
        ) : (
          <span className="sunburst-legend-total-label1">
            <span className="sunburst-legend-total-count">{count || 0}</span>
            {percent && (
              <span className="sunburst-legend-total-percent">
                ({percent}%)
              </span>
            )}
          </span>
        )}
      </span>
    </div>
  )
}

const ChildLegend = (props) => {
  let { title, data, count, percent, nodeColors, totalPercent } = props
  const onLegendClick = function (e, type) {
    Emitter.emit('LegendClick', { data })
  }
  let background = {
    borderLeft: `10px solid ${nodeColors[data.nuuid]}`,
  }
  let progressBar = {
    width: `${percent}%`,
    backgroundColor: `${nodeColors[data.nuuid]}`,
  }
  
  return (
    <div
      className="sunburst-legend-list"
      onClick={onLegendClick}
    >
      <span style={background} className="wheel-legend-title-1">
        <span className="sunburst-legend-label">{title} </span>
        <span className="sunburst-legend-total">
          {percent && (
            <span className="sunburst-legend-total-percent">{percent}%</span>
          )}
          <div className="wheel-progress">
            <div style={progressBar} className="wheel-progress-bar"></div>
          </div>
          <span className="sunburst-legend-total-label1">
            <span className="sunburst-legend-total-count">{count || 0}</span>
            {percent && (
              <span className="sunburst-legend-total-percent">
                ({totalPercent}%)
              </span>
            )}
          </span>
        </span>
      </span>
    </div>
  )
}

const WheelLegend = (props) => {
  const [parentArr, setParentArr] = useState([])
  const [childArray, setChildArray] = useState([])
  const [parentPercent, setParentPercent] = useState('')
  const [parentTotal, setParentTotal] = useState('')
  const [parentTitle, setParentTitle] = useState('')

  const findChildren = (node) => {
    let childArray = []

    if (node.children) {
      node.children.forEach((item, idx) => {
        if (
          !item.data.name.includes('placeholder') &&
          !item.data.name.includes('Undiagnosed')
        ) {
          childArray.push(item)
        }
      })
    }

    return childArray
  }

  let { d, nodeColors } = props

  useEffect(() => {
    d = d.data

    if (d) {
      let _parentArr = d.ancestors().reverse()
      _parentArr.splice(_parentArr.length - 1)
      setParentArr(_parentArr)
      let s1 = d.data.size || d.data.node_size
      let ppercent = 100
      if (_parentArr.length > 0) {
        let p11 = (s1 / _parentArr[0].data.node_size) * 100

        if (p11 < 1.0) {
          ppercent = parseFloat(p11).toFixed(2)
        } else {
          ppercent = Math.round(p11)
        }

        if (ppercent > 100) {
          ppercent = 100
        } else if (isNaN(ppercent)) {
          ppercent = 0
        }
      }

      setParentTitle(d.data.name)
      setParentPercent(ppercent)
      setParentTotal(s1)

      let childArray = findChildren(d)
      childArray.sort((a, b) => a.data.name.localeCompare(b.data.name))
      setChildArray(childArray)
    } else {
    }
  }, [d])
  return (
    <>
      {parentArr.map((_item, idx) => {
        let item = _item.data

        let ps1 = item.size || item.node_size
        let pspercent = 100
        if (parentArr.length > 0) {
          let p1 = (ps1 / parentArr[0].data.node_size) * 100
          if (p1 < 1.0) {
            pspercent = parseFloat(p1).toFixed(2)
          } else {
            pspercent = Math.round(p1)
          }
          if (pspercent > 100) {
            pspercent = 100
          } else if (isNaN(pspercent)) {
            pspercent = 0
          }
        }

        return (
          <GlobalLegend
            key={idx}
            title={item.name}
            data={item}
            parentCount={parentArr.length}
            percent={pspercent}
            count={ps1}
            nodeColors={nodeColors}
          />
        )
      })}
      {
        <ParentLegend
          title={parentTitle}
          data={d.data}
          parentCount={parentArr.length}
          percent={parentPercent}
          count={parentTotal}
          nodeColors={nodeColors}
        />
      }
      {childArray.map((_item, idx) => {
        let item = _item.data
        let s = item.size || item.node_size
        let percent = (s / d.data.data.node_size) * 100

        if (percent < 1.0) {
          percent = parseFloat(percent).toFixed(2)
        } else {
          percent = Math.round(percent)
        }

        if (parentArr.length > 0) {
          let p12 = (s / parentArr[0].data.node_size) * 100
          if (p12 < 1.0) {
            percent = parseFloat(p12).toFixed(2)
          } else {
            percent = Math.round(p12)
          }
        }

        let pcpercent = getPercent(_item)

        if (pcpercent < 1.0) {
          pcpercent = parseFloat(pcpercent).toFixed(2)
        } else {
          pcpercent = Math.round(pcpercent)
        }

        if (pcpercent > 100) {
          pcpercent = 100
        } else if (isNaN(percent)) {
          percent = 0
        }

        return (
          <ChildLegend
            key={idx}
            title={item.name}
            data={item}
            parentCount={parentArr.length}
            percent={pcpercent}
            totalPercent={percent}
            count={s}
            nodeColors={nodeColors}
          />
        )
      })}
    </>
  )
}

const PatientPopulationChart = ({ accountId, data }) => {
  const [wheelData, setWheelData] = useState({})
  const [nodeColors, setNodeColors] = useState({})
  let _nodeColors = {}
  let wheelChartRoot

  const loadWheelInfo = useCallback((accountId, siteNetworkRoot) => {
    const myChart = window.Sunburst()
    let colorArr = d3.quantize(interpolateRainbow, 20)
    colorArr.pop()
    let ccolrs = [
      '#6e40aa',
      '#963db3',
      '#bf3caf',
      '#e4419d',
      '#fe4b83',
      '#ff5e63',
      '#ff7847',
      '#fb9633',
      '#e2b72f',
      '#c6d63c',
      '#1F77B4',
      '#2CA02C',
      '#D62728',
      '#ff9da7',
      '#9467BD',
      '#8C564B',
      '#E377C3',
      '#7F7F7F',
      '#17BECF',
      '#57A9E2',
      '#5FD35F',
      '#E77C7C',
      '#BC8B81',
      '#F4CCE8',
      '#E2E362',
      '#103D5D',
      '#A74E15',
      '#165016',
      '#801718',
      '#4A2D27',
      '#CA2A99',
      '#4C4C4C',
      '#666716',
      '#0D6A73',
      '#FCB441',
      '#005CDB',
      '#056492',
      '#219DA2',
      '#75d3eb',
      '#EF476F',
      '#06D6A0',
      '#118AB2',
      '#F79256',
      '#FBD1A2',
      '#7DCFB6',
      '#FDC500',
      '#FFD500',
      '#00509D',
      '#00296B',
      '#003F88',
      '#FF6B35',
      '#EFEFD0',
      '#F7C59F',
      '#FFB7C3',
      '#0B5502',
      '#36699d',
      '#f18500',
      '#00937a',
      '#933700',
      '#c83131',
      '#8200a8',
      '#224e2a',
      '#1b9e77',
      '#d95f02',
      '#7570b3',
      '#e7298a',
      '#66a61e',
      '#e6ab02',
      '#a6761d',
      '#4e79a7',
      '#e15759',
      '#76b7b2',
      '#af7aa1',
      '#9c755f',
      '#bab0ab',
      '#a6cee3',
      '#b2df8a',
      '#fdbf6f',
      '#cab2d6',
      '#2897bf',
      '#081d58',
      '#037877',
      '#014636',
      '#440154',
      '#482475',
      '#414487',
      '#355f8d',
      '#2a788e',
      '#21918c',
      '#22a884',
      '#44bf70',
      '#bddf26',
    ]

    colorArr = ccolrs.reverse().concat(colorArr).reverse()
    const wheelcolor1 = scaleOrdinal(colorArr)

    const getColorsCodes1 = (title) => {
      return wheelcolor1(title)
    }

    function parseWheelData(siteNetworkRoot) {
      let mRoot = {}

      if (siteNetworkRoot.children) {
        mRoot.name = siteNetworkRoot.name
        mRoot.id = siteNetworkRoot.id

        if (siteNetworkRoot.comment) {
          mRoot.comment = siteNetworkRoot.comment
        } else {
          mRoot.comment = ''
        }

        mRoot.node_size = siteNetworkRoot.size
        mRoot.nuuid = siteNetworkRoot.nuuid
        mRoot.children = []
        mRoot.color = getColorsCodes1(siteNetworkRoot.nuuid)
        mRoot = parseWheelChildNode(
          siteNetworkRoot.children,
          mRoot,
          siteNetworkRoot,
        )
      }

      return mRoot
    }

    function parseWheelChildNode(siteNetworkRoot, mRoot, rootParent) {
      let tNode = mRoot
      let dSize = 0
      siteNetworkRoot.forEach((data, ridx) => {
        let d = {}
        d.name = data.name
        d.id = data.id

        if (data.comment) {
          d.comment = data.comment
        } else {
          d.comment = ''
        }

        d.nuuid = data.nuuid
        d.color = getColorsCodes1(data.nuuid)
        d.children = []
        let rootNode = {}

        if (data.children && data.children.length > 0) {
          d.node_size = data.size
          rootNode = parseWheelChildNode(data.children, d, data)
        } else {
          d.size = data.size
          rootNode = d
        }

        dSize += data.size
        tNode.children.push(rootNode)

        if (ridx === siteNetworkRoot.length - 1) {
          let _size = 1

          if (dSize < rootParent.size) {
            _size = rootParent.size - dSize
          }

          tNode.children.push({
            id: 'placeholder',
            name: 'placeholder',
            size: _size,
          })
        }
      })

      return tNode
    }

    loadWheelData(siteNetworkRoot)

    function loadWheelData(siteNetworkRoot) {
      let node = document.getElementById('chart')

      while (node.hasChildNodes()) {
        node.removeChild(node.firstChild)
      }

      let mRoot = parseWheelData(siteNetworkRoot)
      let resObj = {}
      let nodesnames = []
      let links = []
      let nodes = []
      let nodesList = []
      let nodeColor = {}
      let linkId = ''

      parseWheelData1(siteNetworkRoot)
      resObj.nodes = []
      resObj.nodes = nodesnames
      resObj.links = []
      resObj.links = links

      nodesList.forEach((nl, i) => {
        nodeColor[nl] = colorArr[i % colorArr.length]
      })

      function parseWheelData1(siteNetworkRoot) {
        let depth = 0
        let mRoot = {}

        if (siteNetworkRoot.children) {
          mRoot.name = siteNetworkRoot.name
          nodesList.push(siteNetworkRoot.nuuid)

          if (nodes.indexOf(siteNetworkRoot.name) < 0) {
            nodes.push(siteNetworkRoot.name + '-' + depth)
            nodesnames.push({
              name: siteNetworkRoot.name + '-' + depth,
              nodeId: siteNetworkRoot.name + '-' + depth,
              depth: depth,
            })
          }

          mRoot.id = siteNetworkRoot.id
          mRoot.node_size = siteNetworkRoot.size
          mRoot.children = []
          mRoot = parseWheelChildNode1(
            siteNetworkRoot.children,
            mRoot,
            siteNetworkRoot,
            depth,
          )
        }

        return mRoot
      }

      function parseWheelChildNode1(siteNetworkRoot, mRoot, rootParent, depth) {
        let tNode = mRoot
        let currdepth = depth + 1

        siteNetworkRoot.forEach((data, ridx) => {
          if (currdepth === 1) {
            linkId = data.name
          }

          let d = {}
          d.name = data.name
          let name = data.name + '-' + currdepth
          let rootname = rootParent.name + '-' + depth
          
          if (nodesList.indexOf(data.nuuid) < 0) {
            nodesList.push(data.nuuid)
          }

          if (nodes.indexOf(name) < 0) {
            nodes.push(name)
            nodesnames.push({
              name: name,
              nodeId: name,
              depth: currdepth,
            })
          }

          let filter = links.filter(
            (d) =>
              (d.target === rootname) & (d.source === name) ||
              (d.source === rootname) & (d.target === name),
          )

          if (filter.length <= 0) {
            if (name !== rootname) {
              links.push({
                source: rootname,
                target: name,
                value: data.size,
                id: linkId,
                targetDepth: currdepth,
                sourceDepth: depth,
              })
            }
          }

          d.id = data.id
          d.children = []
          let rootNode = {}

          if (data.children && data.children.length > 0) {
            d.node_size = data.size
            rootNode = parseWheelChildNode1(data.children, d, data, currdepth)
          } else {
            d.size = data.size
            rootNode = d
          }

          tNode.children.push(rootNode)
        })

        return tNode
      }

      const partition = (data) => {
        const siteNetworkRoot = d3
          .hierarchy(data)
          .sum((d) => d.size)
          .sort((a, b) => b.size - a.size)
        return d3.partition().size([2 * Math.PI, siteNetworkRoot.height])(
          siteNetworkRoot,
        )
      }

      const proot = partition(mRoot)
      proot.each((d) => (d.current = d))

      let _root = d3.hierarchy(mRoot)
      _root.sum((d) => d.size)

      myChart
        .chartId('wheelChart')
        .data(mRoot)
        .size('size')
        .width(600)
        .height(600)
        .label('name')
        .size('size')
        .centerRadius(0.05)
        .minSliceAngle(0.1)
        .maxLevels(3)
        .sort((a, b) => b.size - a.size) // sort descending by size
        .color((d) => {
          if (
            d.name.includes('placeholder') ||
            d.name.includes('Undiagnosed')
          ) {
            return '#ffffff'
          } else {
            let nc = ''
            if (_nodeColors) {
              if (_nodeColors[d.nuuid]) {
                nc = _nodeColors[d.nuuid]
              } else {
                nc = nodeColor[d.nuuid]
                _nodeColors[d.nuuid] = nc
              }
            } else {
              nc = nodeColor[d.nuuid]
              _nodeColors = {}
              _nodeColors[d.nuuid] = nc
            }
            return nc
          }
        })
        .tooltipContent((d, node) => `Size: <i>${d.size || d.node_size}</i>`)
        .radiusScaleExponent(1.4)(document.getElementById('chart'))

      let clicked = (d) => {
        if (d) {
          focusOn(d)
          myChart.focusOnNode(d.data)
          let k = partition(proot).descendants()
          let d1 = k.filter((_item) => {
            let item = _item.data

            if (item && item.data && item.data.nuuid) {
              return item.data.nuuid === d.data.nuuid
            }

            return false;
          })

          if (d1.length > 0) {
            setTimeout(() => {
              updateSunburstLegend({
                d: d1[0],
              })
            }, 1000)
          }
        }
      }

      myChart.onClick(clicked)
      Emitter.on('LegendClick', clicked)

      let focusOn = (d = { x0: 0, x1: 1, y0: 0, y1: 1 }) => {
        if (d) {
        }
      }

      function updateSunburstLegend(config) {
        console.log('wheel', config)

        setWheelData(config.d)
      }

      setTimeout(() => {
        setNodeColors(_nodeColors)
      }, 100)

      updateSunburstLegend({
        d: partition(proot).descendants()[0],
      })
    }
  }, [])

  useEffect(() => {
    if (accountId) {
      loadWheelInfo(accountId, data)
    }

    return () => {
      console.log('wheelChartRoot dispose')
      wheelChartRoot?.dispose()
    }
  }, [accountId, loadWheelInfo, wheelChartRoot, data])

  return (
    <ChartWrapper>
      <div id="chart"></div>
      {!isEmpty(wheelData) && (
        <LegendContainerWrapper id="legendContainer">
          <WheelLegend d={wheelData} nodeColors={nodeColors} />
        </LegendContainerWrapper>
      )}
    </ChartWrapper>
  )
}

export default memo(PatientPopulationChart)
