import styled from "styled-components";

export const UserDetailsWrapper = styled.div`
width:98%;
margin:15px auto;
.topheadr {display:flex;
    justify-content: end; margin:10px 0px}
  .addbtn {
    background-color: rgb(131,0,81);
    color: #ffffff;
    font-weight: 700;
    font-size: 16px;
    padding: 6px 15px;
    border: 1px solid #5d7ccc;
    border-radius: 3px;
    line-height:normal;
    :hover{
        cursor:pointer;
        background-color:rgba(131,0,81,0.9)
    }
    span{
        margin-right:10px;
    }
  }  
/* table css start here */

.even-row {
    background-color: #ffffff;
  }
  .odd-row {
    background-color: #f4f4f4;
  }
  .ant-table-container table thead tr th {
    background-color: rgb(131,0,81);
    color: #ffffff;
    font-weight: 600;
    font-size: 16px;
  }
  .ant-table-thead th.ant-table-column-has-sorters:hover, .ant-table-container table > thead > tr:first-child th:hover {
    background-color:rgba(131,0,81,0.9) !important;
  }
  .modeltable .ant-table-tbody td {
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    font-size: 13px;
    color: #000000;
  }
  .ant-table-column-sorter-up.active,
  .ant-table-column-sorter-down.active {
    color: #fff;
  }
  .ant-table-column-sorter-up,
  .ant-table-column-sorter-down {
    font-size: 12px;
  }
  .modeltable .ant-table-thead > tr > th,
  .modeltable .ant-table-tbody > tr > td,
  .modeltable .ant-table tfoot > tr > th,
  .modeltable .ant-table tfoot > tr > td {
    padding: 7px 16px !important;
  }
  .ant-table-container {
    border: 1px solid #bcbcbc;
  }
  .ant-pagination-item-active a,
  .ant-pagination-item-active:hover a,
  .ant-pagination-item:hover a {
    color: #707070;
  }
  .ant-pagination-item-active,
  .ant-pagination-item-active:hover,
  .ant-pagination-item:hover {
    border-color: #707070;
  }
  .ant-pagination-prev:hover .ant-pagination-item-link,
  .ant-pagination-next:hover .ant-pagination-item-link {
    border-color: #707070;
    color: #707070;
  }
  .ant-pagination-prev.ant-pagination-disabled .ant-pagination-item-link,
  .ant-pagination-next.ant-pagination-disabled:hover .ant-pagination-item-link {
    color: rgba(0, 0, 0, 0.25);
    border-color: #d9d9d9;
  }
  .ant-select:not(.ant-select-disabled):hover .ant-select-selector,
  .ant-pagination-options-quick-jumper input:hover {
    border-color: #707070;
  }

  .anticon.anticon-caret-down.ant-table-column-sorter-down {
    display: none;
  }
  .anticon.anticon-caret-down.ant-table-column-sorter-down.active {
    display: block;
  }
  .ant-table-cell.ant-table-column-sort.ant-table-column-has-sorters
    .anticon.anticon-caret-up.ant-table-column-sorter-up {
    display: none;
  }

  .ant-table-cell.ant-table-column-sort.ant-table-column-has-sorters
    .anticon.anticon-caret-up.ant-table-column-sorter-up.active {
    display: block;
  }
  .ant-table-column-sorters {
    display: block;
  }
  .ant-table-column-sorter {
    margin-left: 11px;
  }
  .ant-table-column-sorter-inner {
    position: relative;
    top: 2px;
  }
  .value-ctn {
    font-weight: 700;
    font-size: 12px;
  }

  .edit-container .edit-ctn {
    display: inline-block;
    margin-right: 8px;
}
.edit-container .edit-ctn>div {
  margin-right: 8px;
}.edit-ctn .ant-space-item {
  border-bottom: 1px solid transparent;
}
.edit-ctn a {
  color: rgba(46,84,122,.8);
}.edit-ctn:after {
  background-color: rgba(0,0,0,.85);
  content: "";
  height: 11px;
  width: 1px;
}.edit-container span, .edit-ctn:after {
  display: inline-block;
}.delete-ctn {
  border-bottom: 1px solid transparent;
  color: rgba(121,0,68,.8);
}
.edit-container span, .edit-ctn:after {
  display: inline-block;
}
.delete-ctn:hover {
  border-bottom: 1px solid #790044;
  color: #790044;
  cursor: pointer;
}
.edit-ctn .ant-space-item:hover {
  border-bottom: 1px solid #2e547a;
}
.hide-column{display:none}
`;
