import React, { useEffect, useState, memo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams, useLocation } from 'react-router-dom'
import {
  getAdminSiteDetails,
  getAZStudies,
  fetchAZStudies,
  selectAdminSite,
  getSiteCountries,
  fetchSiteCountries,
} from '../../../store/siteSlice'
import { PostAPIRequest } from '../../../services/api'
import appDefaults from '../../../constants'
import { SiteFormWrapper } from './SiteForm.styles'
import {
  Form,
  Input,
  InputNumber,
  Select,
  Button,
  Row,
  Col,
  notification,
  Divider,
  Checkbox,
} from 'antd'

const { Option } = Select

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 10 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 10 },
  },
}

const openNotificationWithIcon = (type) => {
  if (type === 'success') {
    notification.success({
      message: 'Site Details Save Status',
      description: 'Site details saved successfully',
    })
  } else if (type === 'error') {
    notification.error({
      message: 'Site Details Save Status',
      description: 'Error while saving site details',
    })
  }
}

const SiteForm = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const { id } = useParams()
  const site = useSelector(getAdminSiteDetails) || {}
  const azStudies = (useSelector(getAZStudies) || []).filter((study) =>
    site.indication_type ? study.indication === site.indication_type : true,
  )
  const [form] = Form.useForm()
  const isEditMode = location.pathname.includes('/edit/')
  const [showPhone, setShowPhone] = useState(
    site.site_type === 'AZ Site' || false,
  )
  const { loading: countriesLoading, data: countries } = useSelector(
    getSiteCountries,
  )

  form.setFieldsValue({
    ...site,
    study_names: (site.study_names || '').split(','),
    az_phase_1_studies: (site.az_phase_1_studies || '').split(','),
  })

  const onValuesChange = (values) => {
    setShowPhone(values.site_type === 'AZ Site')
  }

  const onFinish = (values) => {
    console.log('Received values of form: ', values)
    const formValues = {
      ...values,
      id: site.id,
      study_names: values.study_names?.join(','),
      az_phase_1_studies: values.az_phase_1_studies?.join(','),
    }
    PostAPIRequest({ url: appDefaults.api.updateSite, data: formValues })
      .then((res) => {
        openNotificationWithIcon('success')
        dispatch(selectAdminSite(formValues))
        console.log('Successfully updated', res)
      })
      .catch((error) => {
        openNotificationWithIcon('error')
        console.error('Site updated error:', error)
      })
  }

  const handleCancelClick = () => {
    if (id) {
      navigate(`/admin/site/${id}`)
    } else {
      navigate(`/admin/sites`)
    }
  }

  useEffect(() => {
    dispatch(fetchAZStudies())
  }, [dispatch])

  useEffect(() => {
    if (!countriesLoading && countries?.length === 0) {
      dispatch(fetchSiteCountries())
    }
  }, [countries?.length, countriesLoading, dispatch])

  return (
    <SiteFormWrapper>
      <Form
        {...formItemLayout}
        form={form}
        name="site"
        onValuesChange={onValuesChange}
        onFinish={onFinish}
        scrollToFirstError
      >
        <Row>
          <Divider orientation="left">Site Info.</Divider>
          <Col span={12}>
            <Form.Item
              name="regional_site_name"
              label="Site Name"
              rules={[
                {
                  required: true,
                  message: 'Please input site name!',
                  whitespace: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="actual_site_name"
              label="Site Name in English"
              rules={[
                {
                  message: 'Please input site name in english!',
                  whitespace: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="commercial_site_indicator"
              label="Commercial Site Indicator"
              rules={[
                {
                  message: 'Please input commercial cite indicator!',
                  whitespace: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Divider orientation="left">Address</Divider>
          <Col span={12}>
            <Form.Item name="site_address" label="Address">
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="city"
              label="City"
              rules={[
                {
                  required: true,
                  message: 'Please input city!',
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="state"
              label="State/Province"
              rules={[
                {
                  message: 'Please input State/Province!',
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="state_code" label="State/Province Code">
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="country"
              label="Country"
              rules={[
                {
                  required: true,
                  message: 'Please input country!',
                },
              ]}
            >
              <Select
                disabled={isEditMode}
                placeholder="Select indication"
                allowClear
              >
                {countries?.map(({ id, name }) => (
                  <Option value={id}>{name}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="country_code" label="Country Code">
              <Input disabled={isEditMode} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="postal_code" label="Postal Code/Zip">
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Divider orientation="left">Site Characteristics</Divider>
          <Col span={12}>
            <Form.Item name="patient_volume" label="Patient Volume">
              <InputNumber />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="az_patients_recruited_in_last_5years"
              label="# of patients recruited in AZ studies in last 5 years"
            >
              <InputNumber />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="indication_type"
              label="Indication"
              rules={[
                {
                  required: true,
                  message: 'Please select indication!',
                },
              ]}
            >
              <Select
                disabled={isEditMode}
                placeholder="Select indication"
                allowClear
              >
                <Option value="Lung Cancer">Lung Cancer</Option>
                <Option value="Breast Cancer">Breast Cancer</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="site_type" label="Site Type">
              <Select placeholder="Select site type" allowClear>
                <Option value="AZ Site">AZ Site</Option>
                <Option value="Opportunity Site">Opportunity Site</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="diversity_index" label="Diversity Index">
              <Select placeholder="Select diversity index" allowClear>
                <Option value="High">High</Option>
                <Option value="Medium">Medium</Option>
                <Option value="Low">Low</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="profile_type" label="Profile Type">
              <Select placeholder="Select profile type" allowClear>
                <Option value="Community">Community</Option>
                <Option value="Academic">Academic</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="rate_card" label="Rate Card">
              <Select placeholder="Select rate card" allowClear>
                <Option value="Yes">Yes</Option>
                <Option value="No">No</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="master_cda" label="Master CDA">
              <Select placeholder="Select master CDA" allowClear>
                <Option value="Yes">Yes</Option>
                <Option value="No">No</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="status" label="Status">
              <Select placeholder="Select status" allowClear>
                <Option value="Selected">Selected</Option>
                <Option value="Engaged">Engaged</Option>
                <Option value="Initiating">Initiating</Option>
                <Option value="Confirmed">Confirmed</Option>
                <Option value="Recruiting">Recruiting</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="early_phase" label="Phase 1 Potential">
              <Select placeholder="" allowClear defaultValue="No">
                <Option value="Yes">Yes</Option>
                <Option value="No">No</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Divider orientation="left">AZ Studies Info.</Divider>
          <Col span={12}>
            <Form.Item
              name="az_lc_ph2_3_completed_studies"
              label="#AZ Completed Studies"
            >
              <InputNumber />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="no_of_actively_recruiting_az_lc_studies"
              label="#AZ Active Studies"
            >
              <InputNumber />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="total_az_studies" label="#AZ Studies">
              <InputNumber />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="az_phase_1_studies" label="AZ Phase 1 Studies">
              <Select
                placeholder="Select AZ Phase 1 Studies"
                mode="multiple"
                allowClear
                maxTagCount={3}
              >
                <Option key="">None</Option>
                {azStudies.map((study) => (
                  <Option value={study.study_acronym}>
                    {study.study_acronym}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="study_names"
              label="AZ Studies"
              rules={[
                {
                  required: true,
                  message: 'Please input AZ Studies!',
                },
              ]}
            >
              <Select
                placeholder="Select AZ Studies"
                mode="multiple"
                allowClear
                maxTagCount={3}
              >
                <Option key="">None</Option>
                {azStudies.map((study) => (
                  <Option key={study.study_acronym}>
                    {study.study_acronym}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Divider orientation="left">Site Contacts</Divider>
          <Col span={12}>
            <Form.Item name="kee_names" label="KEE Name(s)">
              <Input placeholder="Comma separated KEE names" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="cro_lead_names" label="CRO Lead Name(s)">
              <Input />
            </Form.Item>
          </Col>
          {/* <Col span={12}>
            <Form.Item
              name="email"
              label="Email"
            >
              <Input />
            </Form.Item>
          </Col>
          {showPhone && (
            <Col span={12}>
              <Form.Item
                name="phone"
                label="Phone"
              >
                <Input />
              </Form.Item>
            </Col>
          )} */}
        </Row>
        <Row>
          <Divider orientation="left">Recommend for Partners in Care</Divider>
          <Col span={12}>
            <Form.Item
              name="primary_rationale_for_selection"
              label="Primary Rationale For Selection"
              rules={[
                {
                  required: true,
                  message: 'Please input Primary Rationale For Selection!',
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="is_approved"
              label="Recommend as PICN site"
              valuePropName="checked"
            >
              <Checkbox />
            </Form.Item>
          </Col>
        </Row>
        <Divider orientation="left"></Divider>
        <div className="form-buttons">
          <Button
            className="cancel-button"
            type="secondary"
            onClick={handleCancelClick}
          >
            Cancel
          </Button>
          <Button type="primary" htmlType="submit">
            Save
          </Button>
        </div>
      </Form>
    </SiteFormWrapper>
  )
}
export default memo(SiteForm)
