import styled from 'styled-components'

export const SiteDetailsWrapper = styled.div`
  width: 100%;

  .top-bar {
    margin-bottom: 16px;
    .back-button {
      width: 20%;
    }
    .actions {
      text-align: right;
    }
  }

  .link {
    color: #8a0051;
    font-weight: 500;
    margin-bottom: 16px;
    display: block;
    text-decoration: underline;
  }

  .edit-button {
    background: #43425d;
    color: #fff;
    height: 36px;
    border-radius: 0;
    padding: 0 32px;
    span {
      padding: 0;
    }
  }

  .approve-button {
    background: #43425d;
    color: #fff;
    height: 36px;
    border-radius: 0;
    padding: 0 32px;
    margin-right: 8px;
    span {
      padding: 0;
    }
  }

  .sub-title {
    font-size: 14px;
    color: #80818b;
  }

  .ant-tag {
    padding: 7px;
  }
`

export const SiteTitle = styled.span`
  text-align: left;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0px;
  color: #8a0051;
`
