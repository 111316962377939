const Unauthorized = () => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        textAlign: 'center',
        alignItems: 'center',
        height: '100vh'
      }}
    >
      <div>
        <h1 style={{fontSize: 100}}>401</h1>
        <h2 style={{fontSize: 40}}>You are not allowed to enter here</h2>
      </div>
    </div>
  )
}

export default Unauthorized
