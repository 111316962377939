import styled from 'styled-components'

export const KeeListWrapper = styled.div`
  width: 100%;

  .ant-spin-spinning {
    width: 100%;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`

export const KeeProfile = styled.div`
  .title {
    font-size: 1.5em;
    font-weight: 400;
    color: #43425d;
  }
  .sub-title {
    font-size: 0.8em;
    color: #80818b;
  }
`

export const KeeHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  > div {
    width: 30%;
  }
  .heading {
    font-weight: bold;
    font-size: 1rem;
    text-align: center;
  }
  .source {
    font-size: 0.8rem;
    font-style: italic;
    text-align: right;
    color: #1c45b4;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .kee-search {
      margin-left: 16px;
      width: 200px;
    }
  }
`

export const KeeListContainer = styled.div`
  width: 100%;
`

export const KeeListHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.8rem;
  font-weight: bold;
  color: #fff;
  background-color: #43425d;
  padding: 0 16px;
  margin-top: 1rem;
  position: relative;
  > div {
    width: 25%;
    padding: 8px 16px;
  }
`
