import { Spin } from 'antd'
import Unauthorized from '../containers/Unauthorized'
import useAuth from '../hooks/useAuth'

const AuthRoute = ({ children, isAdminRoute = false }) => {
  const userDetails = useAuth()
  return (
    <Spin
      spinning={userDetails.loading}
      style={{
        display: 'flex',
        height: '100vh',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      {isAdminRoute ? (
        <>
          {!userDetails.loading && !userDetails.error && userDetails.data?.isAdmin && children}
          {!userDetails.loading && (userDetails.error || userDetails.data?.isAdmin === false) && <Unauthorized />}
        </>
      ): (
        <>
          {!userDetails.loading && !userDetails.error && children}
          {!userDetails.loading && userDetails.error && <Unauthorized />}
        </>
      )}
      
    </Spin>
  )
}

export default AuthRoute
