import styled from 'styled-components'

export const KeeDetailsWrapper = styled.div`
  display: block;

  .ant-breadcrumb * {
    color: #8a0151 !important;
    font-size: 1rem;
    font-weight: 500;
  }

  .link a {
    text-decoration: underline;
  }
`

export const KeeContent = styled.div`
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 5px 3px 5px #00000029;
  border-radius: 12px;
  padding: 16px;
  margin: 16px 0;

  > div {
    border: none;
  }

  .primary-kee {
    margin-left: 0;
  }
`

export const KeeContentHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.8rem;
  font-weight: bold;
  color: #fff;
  background-color: #43425d;
  padding: 0 16px;
  > div {
    width: 25%;
    padding: 8px 16px;
  }
`

export const SectionDetails = styled.div`
  .title {
    font-size: 1.5em;
    font-weight: 500;
    color: #43425d;
  }
  .sub-title {
    font-size: 0.8em;
    color: #80818b;
  }
`

export const TabsWrapper = styled.div`
  position: relative;

  .source {
    position: absolute;
    right: 16px;
    top: 13px;
    font-size: 0.8rem;
    font-style: italic;
    text-align: right;
    color: #1c45b4;
  }

  .ant-tabs-nav {
    margin-bottom: 0;
    z-index: 9;
    top: 3px;
  }

  .ant-tabs-tab {
    margin: 0 48px;
    .ant-tabs-tab-btn {
      color: #8a8a8a;
      font-size: 12px;
      font-weight: bold;
    }
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #454545;
  }

  .ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar {
    padding: 0 16px;
    box-sizing: content-box;
    margin-left: -16px;
    background: #8a0151;
    height: 3px;
  }

  .ant-tabs-content-holder {
    position: relative;
    padding: 8px;
    padding-top: 0;
  }

  .ant-tabs-content {
    border-top: solid 3px #e0e0e0;
    background: 0% 0% no-repeat padding-box padding-box rgb(255, 255, 255);
    box-shadow: rgb(0 0 0 / 16%) 5px 3px 5px;
    padding: 16px;
  }
`
