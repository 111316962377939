import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  details: {},
}

export const keeSlice = createSlice({
  name: 'kee',
  initialState,
  reducers: {
    selectKee: (state, action) => {
      state.details = action.payload
    },
  },
})

export const { selectKee } = keeSlice.actions

export const getKeeDetails = (state) => state.kee?.details

export default keeSlice.reducer
