import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import appDefaults from '../constants'
import { GetAPIRequest } from '../services/api'

const initialState = {
  details: {},
  data: { loading: false, data: [], error: false },
  AdminSiteDetails: {},
  AdminSites: { loading: false, data: [], error: false },
  azStudies: { loading: false, data: [], error: false },
  siteCountries: { loading: false, data: [], error: false },
}

export const fetchSites = createAsyncThunk('site/fetchSites', async () => {
  const response = await GetAPIRequest({ url: appDefaults.api.getSites })
  return { loading: false, data: response, error: false }
})

export const fetchAdminSites = createAsyncThunk(
  'site/fetchAdminSites',
  async (country) => {
    const response = await GetAPIRequest({
      url: `${appDefaults.api.getSitesByCountry}/${country}`,
    })
    return {
      loading: false,
      data: response
        ?.sort((a, b) => b.weighted_score - a.weighted_score)
        .sort((a, b) => b.is_approved - a.is_approved),
      error: false,
    }
  },
)

export const fetchAZStudies = createAsyncThunk(
  'site/fetchAZStudies',
  async () => {
    const response = await GetAPIRequest({ url: appDefaults.api.getAZStudies })
    return { loading: false, data: response, error: false }
  },
)

export const fetchSiteCountries = createAsyncThunk(
  'site/fetchSiteCountries',
  async () => {
    const response = await GetAPIRequest({
      url: appDefaults.api.getSiteCountries,
    })
    return {
      loading: false,
      data: response.map(({ country }) => ({ name: country, id: country })),
      error: false,
    }
  },
)

export const siteSlice = createSlice({
  name: 'site',
  initialState,
  reducers: {
    selectSite: (state, action) => {
      state.details = action.payload
    },
    selectAdminSite: (state, action) => {
      state.AdminSiteDetails = action.payload
    },
  },
  extraReducers: {
    [fetchSites.pending]: (state) => {
      state.data = { loading: true, data: [], error: false }
    },
    [fetchSites.fulfilled]: (state, action) => {
      state.data = action.payload
    },
    [fetchSites.rejected]: (state) => {
      state.data = { loading: false, data: [], error: true }
    },
    [fetchAdminSites.pending]: (state) => {
      state.AdminSites = { loading: true, data: [], error: false }
    },
    [fetchAdminSites.fulfilled]: (state, action) => {
      state.AdminSites = action.payload
    },
    [fetchAdminSites.rejected]: (state) => {
      state.AdminSites = { loading: false, data: [], error: true }
    },
    [fetchAZStudies.pending]: (state) => {
      state.azStudies = { loading: true, data: [], error: false }
    },
    [fetchAZStudies.fulfilled]: (state, action) => {
      state.azStudies = action.payload
    },
    [fetchAZStudies.rejected]: (state) => {
      state.azStudies = { loading: false, data: [], error: true }
    },
    [fetchSiteCountries.pending]: (state) => {
      state.siteCountries = { loading: true, data: [], error: false }
    },
    [fetchSiteCountries.fulfilled]: (state, action) => {
      state.siteCountries = action.payload
    },
    [fetchSiteCountries.rejected]: (state) => {
      state.siteCountries = { loading: false, data: [], error: true }
    },
  },
})

export const { selectSite, selectAdminSite } = siteSlice.actions

export const getSiteDetails = (state) => state.site.details
export const getSites = (state) => state.site.data
export const getAdminSiteDetails = (state) => state.site.AdminSiteDetails
export const getAdminSites = (state) => state.site.AdminSites
export const getAZStudies = (state) => state.site.azStudies.data
export const getSiteCountries = (state) => state.site.siteCountries

export default siteSlice.reducer
