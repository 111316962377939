import { memo } from 'react'
import SiteCard from '../SiteCard'
import communityIcon from '../../img/community.svg'
import academicIcon from '../../img/academic.svg'
import {
  SiteListWrapper,
  SiteListHeader,
  SiteGroupLegend,
  SiteGroup,
} from './SiteList.styles'
import { Empty } from 'antd'

const SiteList = ({ data, onSiteSelect, countryName }) => {
  return (
    <SiteListWrapper>
      <SiteListHeader>
        <SiteGroupLegend>
          <SiteGroup>
            <img src={communityIcon} alt="" />
            <span className="site-group">Community</span>
          </SiteGroup>
          <SiteGroup>
            <img src={academicIcon} alt="" />
            <span className="site-group">Academic</span>
          </SiteGroup>
        </SiteGroupLegend>
        <div className="heading">Sites in {countryName}</div>
        <div className="source">
          <strong>Site Intel Source(s):</strong> Merlin, Symphony, IQVIA.
        </div>
      </SiteListHeader>
      {data?.map((site, index) => (
        <SiteCard key={index} data={site} onSiteSelect={onSiteSelect} />
      ))}
      {!data?.length && <Empty description="Sites are unavaliable" />}
    </SiteListWrapper>
  )
}

export default memo(SiteList)
