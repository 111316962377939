import styled from 'styled-components'

export const SiteCardWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 5px 3px 5px #00000029;
  border-radius: 12px;
  padding: 12px;
  justify-content: space-between;
  margin-bottom: 16px;

  &:last-of-type {
    margin-bottom: 4px;
  }

  &.loading {
    flex-direction: column;
  }

  .ant-divider-horizontal {
    margin: 12px 0;
  }
`

export const SiteImage = styled.img`
  width: 180px;
  height: 180px;
  border: 1px solid #c3c3c3;
  @media screen and (max-width: 1180px) {
    width: 100px;
    height: 100px;
  }
`

export const SiteHeader = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  margin: 0 22px 0 22px;
  width: 250px;
`

export const SiteTitle = styled.span`
  text-align: left;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0px;
  color: #8a0051;
`

export const SiteDescription = styled.div`
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #000000;
`

export const SiteGroup = styled.div`
  height: 70px;
  display: flex;
  align-items: center;

  .site-group {
    padding-left: 12px;
    font-size: 12px;
    text-align: left;
    letter-spacing: 0px;
    color: #4d4f5c;
    text-transform: capitalize;
  }
`

export const SiteDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-wrap: wrap;
`

export const SiteDetails = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  flex-wrap: wrap;
`

export const Datafield = styled.div`
  display: flex;
  flex-direction: column;
  padding: 3px;
  width: 20%;
`

export const DataFieldHeader = styled.label`
  text-align: center;
  font-weight: bold;
  font-size: 12px;
  letter-spacing: 0px;
  color: #000000;
`

export const DataFieldValue = styled.span`
  text-align: center;
  font-size: 16px;
  letter-spacing: 0px;
  color: #000000;
`

export const SiteStudies = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-left: 20px;

  label {
    padding-right: 20px;
  }

  .ant-btn-link {
    padding: 0px;
    span {
      padding: 0px;
    }
  }

  .view-button {
    background: #43425d;
    color: #fff;
    height: 36px;
    border-radius: 0;
    span {
      padding: 0;
    }
  }
`
