import { memo } from 'react'
import { Spin, Skeleton, Empty } from 'antd'
import moment from 'moment'
import {
  KeeActivitiesWrapper,
  ListHeader,
  ListContent,
  ListItem,
} from './KeeTimeline.styles'
import CalendarIcon from '../../img/calendar.png'
import ClinicalTrialIcon from '../../img/clinical-trial.svg'
import GuidelineIcon from '../../img/guideline.svg'
import MentionIcon from '../../img/mention.svg'
import PublicationIcon from '../../img/publication.svg'

const getIcon = (type) => {
  switch (type?.toLowerCase()) {
    case 'clinicaltrial':
      return ClinicalTrialIcon
    case 'event':
      return CalendarIcon
    case 'guideline':
      return GuidelineIcon
    case 'mention':
      return MentionIcon
    case 'publication':
      return PublicationIcon
    default:
      return PublicationIcon
  }
}

const getTypeText = (type) => {
  switch (type?.toLowerCase()) {
    case 'clinicaltrial':
    case 'event':
      return 'Started'
    case 'guideline':
      return 'Published on'
    case 'publication':
      return 'Published on'
    default:
      return 'Started'
  }
}

const KeeActivities = ({ keeTimeLineData: { loading, data } }) => {
  return (
    <KeeActivitiesWrapper>
      <ListHeader>
        <div className="what">What?</div>
        <div className="when">When?</div>
      </ListHeader>
      <Spin className="loading" spinning={loading} />
      <Skeleton active loading={loading} />
      {!loading && (
        <ListContent>
          {data?.length > 0 ? (
            data.map(
              (
                {
                  abstract,
                  address,
                  expertContributions,
                  headerText,
                  type,
                  title,
                  purpose,
                  startDate,
                },
                index,
              ) => (
                <ListItem key={index}>
                  <div className="what">
                    <div className="icon">
                      <img src={getIcon(type)} alt="" />
                    </div>
                    <div className="content-wrapper">
                      <div>
                        <div className="heading">{headerText}</div>
                        <div className="details">{title}</div>
                        {abstract && (
                          <details>
                            <summary className="heading">Abstract</summary>
                            <p className="details">{abstract}</p>
                          </details>
                        )}
                        {address && (
                          <div className="heading">
                            {moment(startDate).format('MMMM DD, YYYY')} |{' '}
                            {address.city}, {address.country}
                          </div>
                        )}
                      </div>
                      {expertContributions && (
                        <div className="session">
                          <span className="heading">Session: </span>
                          {expertContributions.map(
                            ({ contributionId, session }) => (
                              <a
                                key={contributionId}
                                className="details"
                                target="_blank"
                                href={session.sessionWebSource}
                                rel="noreferrer"
                              >
                                {session.sessionTitle}
                              </a>
                            ),
                          )}
                        </div>
                      )}
                      {purpose && (
                        <details>
                          <summary className="heading">Purpose</summary>
                          <p className="details">{purpose}</p>
                        </details>
                      )}
                    </div>
                  </div>
                  <div className="when date">
                    {getTypeText(type)}{' '}
                    {moment(startDate).format('MMM DD, YYYY')}
                  </div>
                </ListItem>
              ),
            )
          ) : (
            <Empty description="No timeline data available" />
          )}
        </ListContent>
      )}
    </KeeActivitiesWrapper>
  )
}

export default memo(KeeActivities)
