import styled from 'styled-components'

export const SearchWrapper = styled.div`
  display: flex;
  flex: 1;
  .select {
    flex: 1;
    box-shadow: 0px 3px 6px #00000029;
  }
  .ant-btn-primary {
    background-color: transparent;
    border-color: #eeeeee;
    margin-left: 1px;
    color: #015dab;
    border-left: none;
  }
  .stateName {
    font-size: 12px;
  }
  .search-container {
    position: relative;
  }
  .close-icon {
    position: absolute;
    right: 58px;
    top: 13px;
    cursor: pointer;
    z-index: 99;
  }
  .ant-select-clear {
    margin-right: 35px;
  }
`
