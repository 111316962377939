import styled from 'styled-components'

export const SegmentedButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0px 10px;
  .ant-radio-group-solid
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    color: #fff;
    background: #8a0051;
    border-color: #8a0051;
    &:focus-within {
      box-shadow: none;
    }
  }

  .title {
    margin-bottom: 5px;
  }
  .academic-btn {
    font-weight: bold;
    color: #2d75e6;
  }
  .usoncology-btn {
    font-weight: bold;
    color: #ff4500;
  }
  .scri-btn {
    font-weight: bold;
    color: #ff8c00;
  }
  .new-oppor-btn {
    font-weight: bold;
    color: #cb0003;
  }
  .hot-spot-btn {
    font-weight: bold;
    color: #dd5044;
  }
  .community-btn {
    font-weight: bold;
    color: #af0062;
    :hover: {
      color: #af0062;
    }
  }
  .all-btn {
    font-weight: bold;
    color: #666666;
  }
  .high-btn {
    font-weight: bold;
    color: #d00000;
  }
  .medium-btn {
    font-weight: bold;
    color: #33658a;
  }
  .low-btn {
    font-weight: bold;
    color: #f46036;
  }

  @media screen and (max-width: 1180px) {
    .ant-radio-button-wrapper {
      font-size: 10px;
      min-width: unset;
      padding: 5px 8px;
    }
  }
`
