import { memo } from 'react'
import { Button } from 'antd'
import { useCallback } from 'react'
import {
  KeeListItemWrapper,
  KeeListItemDetails,
  KeeActivities,
} from './KeeListItem.styles'

const totalActivityCount = 10
const getInitialName = (name) => {
  const parts = name?.split(' ')
  return parts && `${parts[0][0]}${parts[1][0]}`
}

const KeeListItem = ({
  data,
  hideViewDetails = true,
  onViewDetailsClick = () => {},
}) => {
  const handleViewKeeDetailsClick = useCallback(() => {
    onViewDetailsClick(data)
  }, [data, onViewDetailsClick])

  return (
    data &&
    Object.keys(data).length > 0 && (
      <KeeListItemWrapper key={data.name}>
        <KeeListItemDetails
          className={`${
            data.kee_type?.toLowerCase() === 'primary' ? 'primary-kee' : ''
          }`}
        >
          <div className="doctor-image">
            {data.image_url && data.image_url !== 'undefined' ? (
              <img src={data.image_url} alt="" />
            ) : (
              <div className="image-placeholder">
                {getInitialName(data.name)}
              </div>
            )}
            <div className="name-wrapper">
              <div className="name">Dr. {data.name}</div>
              <div className="caption">
                {data.primary_affiliation_position} at{' '}
                {data.primary_affiliation_name}
              </div>
            </div>
          </div>
          <div>
            <div>{data.address?.street},</div>
            <div>
              {data.address?.city}, {data.address?.state},
            </div>
            <div>
              {data.address?.zip}, {data.address?.country}
            </div>
          </div>
          <div>{data.no_of_trails}</div>
          <div>
            {data.specialisations
              ?.filter((e) => e.is_primary)
              .map((item) => (
                <div key={item.name}>{item.name}</div>
              ))}
          </div>
        </KeeListItemDetails>
        <KeeActivities>
          <div className="activity-label">Activities</div>
          <div className="activity-container">
            {data.activities?.map((ativity, index) => (
              <div key={index} className="activity">
                <div className="activity-percentage">
                  <div
                    style={{
                      width: `${(ativity.decile / totalActivityCount) * 100}%`,
                    }}
                    className="precentage"
                  ></div>
                </div>
                <div className="activity-name">{ativity.name}</div>
              </div>
            ))}
          </div>
          {!hideViewDetails && (
            <Button className="view-button" onClick={handleViewKeeDetailsClick}>
              View Details
            </Button>
          )}
        </KeeActivities>
      </KeeListItemWrapper>
    )
  )
}

export default memo(KeeListItem)
