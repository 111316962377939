import { memo } from 'react'
import { Select } from 'antd'
import { FilterWrapper } from './Filter.styles'

const Filter = (props) => {
  const {
    title,
    data,
    placeholder,
    onChange,
    mode = '',
    style = {},
    allowClear = false,
    defaultValue,
    selectedValue,
  } = props

  return (
    <FilterWrapper>
      <div className="filterTitle">{title}</div>
      <div className="filterDropdown">
        <Select
          mode={mode}
          style={style}
          maxTagCount={'responsive'}
          allowClear={allowClear}
          className="selectField"
          showSearch={false}
          defaultValue={
            defaultValue ? defaultValue : data.length > 0 ? data[0].id : ''
          }
          placeholder={placeholder}
          optionFilterProp="children"
          onChange={onChange}
          value={selectedValue}
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {data.map((item, idx) => (
            <Select.Option key={idx} value={item.id}>
              {item.name}
            </Select.Option>
          ))}
        </Select>
      </div>
    </FilterWrapper>
  )
}

export default memo(Filter)
