import styled from 'styled-components'

export const SiteListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 8px;
`

export const SiteListHeader = styled.div`
  margin: 16px 0;
  display: flex;
  align-items: cetnter;
  justify-content: space-between;
  align-items: center;
  div {
    width: 30%;
  }
  .heading {
    font-weight: bold;
    font-size: 20px;
    text-align: center;
  }
  .source {
    font-size: 0.8rem;
    font-style: italic;
    text-align: right;
    color: #1c45b4;
  }
`

export const SiteGroupLegend = styled.div`
  display: flex;
`

export const SiteGroup = styled.div`
  margin-right: 30px;
  display: flex;
  align-items: center;
  img {
    width: 40px;
  }
  .site-group {
    padding-left: 12px;
    font-size: 12px;
    text-align: left;
    letter-spacing: 0px;
    color: #4d4f5c;
    text-transform: capitalize;
  }
`
