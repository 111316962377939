import styled from 'styled-components'

export const WorldMapWrapper = styled.div`
  width: 100%;
  height: 500px;
`

export const WorldBubbleMap = styled.div`
  width: 100%;
  height: 500px;
`
