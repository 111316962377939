import { memo } from 'react'
import { CaretUpFilled, CaretDownFilled } from '@ant-design/icons'
import { SortFieldWrapper } from './SortField.styles'

const SortField = ({ field: { name, displayName, order }, onSort }) => {
  return (
    <SortFieldWrapper className="ant-table-column-sorters">
      <span className="ant-table-column-title" name={name} onClick={onSort}>
        {displayName}
      </span>
      <span className="ant-table-column-sorter ant-table-column-sorter-full">
        <span className="ant-table-column-sorter-inner">
          <CaretUpFilled
            size="small"
            className={`ant-table-column-sorter-up ${
              order === 1 ? 'active' : ''
            }`}
          />
          <CaretDownFilled
            size="small"
            className={`ant-table-column-sorter-down ${
              order === -1 ? 'active' : ''
            }`}
          />
        </span>
      </span>
    </SortFieldWrapper>
  )
}

export default memo(SortField)
