import styled from 'styled-components'

export const SiteFormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0 0 5px 5px #00000029;
  border-radius: 12px;
  padding: 24px;
  justify-content: space-between;
  margin-bottom: 16px;
  margin: 0 auto;
  &:last-of-type {
    margin-bottom: 4px;
  }

  &.loading {
    flex-direction: column;
  }

  .ant-divider-horizontal {
    margin: 12px 0;
  }

  .form-buttons {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: 32px;
    margin-left: -98px;
    button {
      height: 36px;
      width: 80px;
    }
  }

  .cancel-button {
    margin-right: 16px;
  }

  .ant-divider-horizontal.ant-divider-with-text-left::after,
  .ant-divider-horizontal.ant-divider-with-text-left::before {
    top: 10%;
    border-top: 1px solid #d9d9d9;
  }

  .ant-divider-horizontal {
    margin-bottom: 40px;
    font-size: 18px;
  }

  label {
    align-items: flex-end;
    overflow: unset;
    white-space: pre-line;
    position: relative;
    top: -6px;
  }
`
