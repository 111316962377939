import { Header } from 'antd/lib/layout/layout'
import React, { memo } from 'react'
import { Link, useLocation } from 'react-router-dom'
import Brand from '../Brand'
import HeaderSearch from '../HeaderSearch/HeaderSearch'
import UserInfo from '../UserInfo'
import { HeaderWrapper } from './Header.styles'
import useAuth from '../../hooks/useAuth'
import { Menu } from 'antd'
//import { width } from '@amcharts/amcharts4/.internal/core/utils/Utils'
import { useState } from 'react'
import { useEffect } from 'react'

const AZHeader = () => {
  const location = useLocation()
  const userDetails = useAuth() ;
   const [selectedKey, setSelectedKey] = useState('');
  

  const handleMenuClick = (e) => {
    setSelectedKey(e.key);
  };

  useEffect(() => {
    const storedSelectedKey = localStorage.getItem('selectedKey');
    setSelectedKey(storedSelectedKey || '1');
  }, []);

  useEffect(() => {
    localStorage.setItem('selectedKey', selectedKey);
  }, [selectedKey]);

  useEffect(() => {
    const pathname = location.pathname;
    if (pathname === '/admin/userdetails') {
      setSelectedKey('1');
    } else if (pathname === '/admin/sites') {
      setSelectedKey('2');
    }
  }, [location.pathname]);
  // useEffect(() => {
  //   //setSelectedKey('1');
  //   localStorage.setItem('selectedKey', selectedKey );
  //   console.log("selected", selectedKey)
  // },[selectedKey])
  return (
    <HeaderWrapper>
      <Header className="header">
       <div className='header-left-container' style={location.pathname.includes('admin') === 'admin'  ? {'width' : '30%'} : {'width' : '60%'} } > <Brand />
       
        
        {location.pathname.includes('admin') && 
      
       <Menu mode="horizontal" selectedKeys={[selectedKey]}
       onClick={handleMenuClick}>
         <Menu.Item key="1">
           <Link to='/admin/userdetails'>User Details</Link>
         </Menu.Item>
         <Menu.Item key="2">
           <Link to='/admin/sites'>Sites Details</Link>
         </Menu.Item>
         </Menu>  
       
  }
  </div>
        {!location.pathname.includes('admin') && <HeaderSearch />}
        <div className="flex">
          {location.pathname.includes('admin') ? (
            <Link to="/">PICN</Link>
          ) : (
            userDetails.data?.isAdmin && <Link to="/admin/userdetails">Admin Portal</Link>
          )}
          <UserInfo />
        </div>
      </Header>
    </HeaderWrapper>
  )
}

export default memo(AZHeader)
