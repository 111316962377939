import styled from 'styled-components'

export const SitesTableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  .siteTableHeader {
    display: flex;
    flex-direction: row;
    margin: 10px 0px;
  }
  .siteTableTitle {
    flex: 1;
    display: flex;
    font-size: 24px;
    align-items: center;
    justify-content: flex-start;
  }
  .siteTableDataSource {
    flex: 1;
    display: flex;
    font-size: 16px;
    align-items: center;
    justify-content: flex-end;
  }
  .siteName {
    font-size: 14px;
    color: #810e4e;
    font-weight: normal;
    text-decoration: underline;
  }
  .siteAddress {
    font-size: 14px;
    color: #3e3e3e;
  }
  .viewBtn {
    background: #34324b;
    color: #ffffff;
    padding: 5px 10px;
    text-align: center;
    cursor: pointer;
  }
  .statusView {
    .greenStatusView {
      background-color: #228b22;
      border-radius: 9px;
      width: 18px;
      height: 18px;
      padding: 9px;
    }
    .yellowStatusView {
      background-color: #ffda00;
      border-radius: 9px;
      width: 18px;
      height: 18px;
      padding: 9px;
    }
    .redStatusView {
      background-color: #ff0000;
      border-radius: 9px;
      width: 18px;
      height: 18px;
      padding: 9px;
    }
  }
`
