import * as am5 from "@amcharts/amcharts5";
import * as am5map from "@amcharts/amcharts5/map";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import React, { useEffect } from "react";
import { WorldMapWrapper, WorldBubbleMap } from "./WorldMap.styles";
import { geoCylindricalStereographic } from "d3-geo-projection";
import { useState } from "react";
import Emitter from "../../utils/emitter";
import {
  createBackButtonContainer,
  createBackgroundSeries,
  createChart,
  createCountrySeries,
  createCountryBubbleSeries,
  createHomeButton,
  createPointSeries,
  createSiteBubbleSeries,
  createTooltip,
  createWorldSeries,
  createZoomControl,
  updateData,
  updateDataSite
} from "./utils";

const WorldMap = (props) => {
  const [data, setData] = useState(props.data);
  const [siteData, setSiteData] = useState(props.siteData);

  var root,
    worldSeries,
    backgroundSeries,
    countryBubbleSeries,
    countrySeries,
    siteBubbleSeries,
    pointSeries1,
    pointSeries,
    chart,
    siteCircleTemplate;

  useEffect(() => {
    if (root != null) {
      root.container.children.clear();
    }

    root = am5.Root.new("WorldBubbleMap");
    root.setThemes([am5themes_Animated.new(root)]);
    chart = createChart(root);
    chart.set("tooltip", createTooltip(root));

    const zoomControl = createZoomControl(root);
    const homeButton = zoomControl.children.moveValue(createHomeButton(root), 0);

    homeButton.events.on("click", function () {
      chart.goHome();
    });
    chart.set("zoomControl", zoomControl);
    chart.chartContainer.get("background").events.on("click", function () {
      chart.goHome();
    });

    worldSeries = createWorldSeries(chart, root);
    backgroundSeries = createBackgroundSeries(chart, root);
    countrySeries = createCountrySeries(chart, root);

    // Add button to go back to continents view
    const backContainer = createBackButtonContainer(chart, root);

    backContainer.events.on("click", function () {
      sessionStorage.setItem("selType", "All");
      sessionStorage.setItem("selCategory", "All");
      sessionStorage.setItem("selBreastSiteCategory", "All");
      chart.set("projection", geoCylindricalStereographic());
      chart.set("maxZoomLevel", 6);
      chart.set("minZoomLevel", 1);

      setSiteData(props.siteData);
      backgroundSeries.show();
      worldSeries.show();
      countrySeries.hide();
      backContainer.hide();
      countryBubbleSeries.show();
      homeButton.show();
      siteBubbleSeries.hide();
      pointSeries1.hide();
      pointSeries.show();
      setTimeout(() => {
        chart.goHome();
      }, 10);

      props.onMapBack();
    });

    pointSeries = createPointSeries(chart, root);
    countryBubbleSeries = createCountryBubbleSeries(chart, root, (data) => updateDataSite({
      chart,
      data,
      countrySeries,
      worldSeries,
      pointSeries,
      backContainer,
      countryBubbleSeries,
      siteBubbleSeries,
      homeButton,
      pointSeries1,
      onCountrySelect: props.onCountrySelect,
      siteData
    }));
    countryBubbleSeries.data.setAll(data);
    siteCircleTemplate = am5.Template.new(root);
    siteBubbleSeries = createSiteBubbleSeries(chart, root, siteCircleTemplate, props.onSiteSelect);
    pointSeries1 = chart.series.push(am5map.MapPointSeries.new(root, {}));
    pointSeries1.bullets.push(function () {
      return am5.Bullet.new(root, {
        sprite: am5.Label.new(root, {
          centerX: am5.p50,
          centerY: am5.p50,
          text: "{name}",
          fill: am5.color(0x999999),
          populateText: true,
          fillOpacity: 0.6,
          cursorOverStyle: "pointer",
          fontSize: "8px",
        }),
      });
    });

    const selectedCountryCode = sessionStorage.getItem("selectedCountryCode");

    if (selectedCountryCode) {
      const countryData = props.data.find(item => item.id === selectedCountryCode);
      if (countryData) {
        setTimeout(() => {
          updateDataSite({
            chart,
            data: countryData,
            countrySeries,
            worldSeries,
            pointSeries,
            backContainer,
            countryBubbleSeries,
            siteBubbleSeries,
            homeButton,
            pointSeries1,
            onCountrySelect: props.onCountrySelect,
            siteData
          });
        }, 100);
      }
    }

    return () => {
      console.log("root dispose");
      root.dispose();
    };
  }, []);

  const updateMap = (data) => {
    updateData(
      data,
      {
        countryBubbleSeries,
        siteBubbleSeries,
        siteCircleTemplate,
        setData,
        setSiteData
      }
    );
  };

  useEffect(() => {
    Emitter.on("UPDATE_DATA", updateMap);
    return () => {
      Emitter.off("UPDATE_DATA", updateMap);
    };
  }, []);

  return (
    <WorldMapWrapper>
      <WorldBubbleMap id="WorldBubbleMap"> </WorldBubbleMap>{" "}
    </WorldMapWrapper>
  );
};

export default WorldMap;
