import { useCallback, useEffect, useState, memo } from 'react'
import { Empty, Spin, Skeleton, Input } from 'antd'
import {
  KeeListWrapper,
  KeeProfile,
  KeeHeader,
  KeeListContainer,
  KeeListHeader,
} from './KeeList.styles'
import KeeListItem from '../KeeListItem'
import SortField from '../SortField'

const { Search } = Input

const KeeList = ({
  keeData: { loading, data },
  siteName,
  onKeeSelect,
  hideViewDetails = false,
}) => {
  const [sortField, setSortField] = useState({
    name: 'no_of_trails',
    order: -1,
  })
  const [primaryKee, setPrimaryKee] = useState()
  const [keeListData, setKeeListData] = useState([])

  const onSearch = useCallback((value) => {
    const result = data.filter((item) =>
      item.name?.toLowerCase().includes(value.toLowerCase()),
    )
    setKeeListData(result)
  }, [data])

  const sortData = useCallback((data, sort, primaryKeeData) => {
    const sortedData = data.sort((a, b) => {
      var result =
        a[sort.name] < b[sort.name] ? -1 : a[sort.name] > b[sort.name] ? 1 : 0
      return result * sort.order
    })

    primaryKeeData
      ? setKeeListData([primaryKeeData, ...sortedData])
      : setKeeListData([...sortedData])
  }, [])

  const handleSort = useCallback(
    (event) => {
      const sortOrder =
        sortField.order === 0 ? -1 : sortField.order === -1 ? 1 : 0
      const sort = { name: event.target.getAttribute('name'), order: sortOrder }

      setSortField(sort)

      if (sortOrder === 0) {
        setKeeListData([...data])
      } else {
        sortData(keeListData, sort, primaryKee)
      }
    },
    [sortField.order, data, sortData, keeListData, primaryKee],
  )

  useEffect(() => {
    if (data?.length) {
      const primaryKeeData = data.find((kee) => kee.kee_type === 'primary')
      const keeList = primaryKeeData
        ? data.filter((kee) => kee.id !== primaryKeeData.id)
        : data

      setPrimaryKee(primaryKeeData)
      setKeeListData(keeList)
      sortData([...keeList], sortField, primaryKeeData)
    }
  }, [data, sortData, sortField])

  return (
    <KeeListWrapper>
      <KeeHeader>
        <KeeProfile>
          <div className="title">KEE Profile</div>
          <div className="sub-title">
            Click on <strong>View Details</strong> to know more
          </div>
        </KeeProfile>
        <div className="heading">List of KEE's at {siteName} Oncology Site</div>
        <div className="source">
          <span>
            <strong>Site Intel Source:</strong> Veeva Link
          </span>
          <Search
            className="kee-search"
            placeholder="Search for a KEE"
            allowClear
            onSearch={onSearch}
          />
        </div>
      </KeeHeader>
      <KeeListHeader>
        <SortField
          field={{
            name: 'name',
            displayName: 'Name and Location',
            order: sortField.name === 'name' ? sortField.order : 0,
          }}
          onSort={handleSort}
        />
        <div>Address</div>
        <SortField
          field={{
            name: 'no_of_trails',
            displayName: '# of Trials',
            order: sortField.name === 'no_of_trails' ? sortField.order : 0,
          }}
          onSort={handleSort}
        />
        <div>Specialisation</div>
      </KeeListHeader>
      <Spin spinning={loading} />
      <Skeleton active loading={loading} />
      {!loading && (
        <KeeListContainer>
          {keeListData?.length > 0 ? (
            keeListData.map((item, index) => (
              <KeeListItem
                key={index}
                data={item}
                onViewDetailsClick={onKeeSelect}
                hideViewDetails={hideViewDetails}
              />
            ))
          ) : (
            <Empty description="No KEE data available" />
          )}
        </KeeListContainer>
      )}
    </KeeListWrapper>
  )
}

export default memo(KeeList)
