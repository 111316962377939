import { useCallback, useState, useEffect, memo } from 'react'
import { NavLink, useParams } from 'react-router-dom'
import { Breadcrumb, Empty, Skeleton, Spin, Tabs } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { isEmpty } from 'lodash'

import KeeOverview from '../../components/KeeOverview'
import KeeTimeline from '../../components/KeeTimeline'
import KeeListItem from '../../components/KeeListItem'
import { selectKee, getKeeDetails } from '../../store/keeSlice'
import CONSTANTS from '../../constants'
import { GetAPIRequest } from '../../services'
import {
  KeeDetailsWrapper,
  KeeContentHeader,
  KeeContent,
  SectionDetails,
  TabsWrapper,
} from './KeeDetails.styles'

const { TabPane } = Tabs

const KeeDetails = () => {
  const dispatch = useDispatch()
  const keeDetails = useSelector(getKeeDetails)
  const [activeTab, setActiveTab] = useState('1')
  const [keeData, setKeeData] = useState({
    loading: false,
    data: { ...keeDetails },
    error: null,
  })

  const handleTabChange = useCallback((selectdTab) => {
    setActiveTab(selectdTab)
  }, [])

  const { siteId, keeId } = useParams()

  const fetchKeeDetails = useCallback(
    (id) => {
      setKeeData({ loading: true, data: [], error: null })
      GetAPIRequest({
        url: `${CONSTANTS.api.getKeeDetails}/${id}`,
      })
        .then((res) => {
          dispatch(selectKee(res))
          setKeeData({ loading: false, data: res, error: null })
        })
        .catch((error) => {
          setKeeData({ loading: false, data: {}, error: error.message })
        })
    },
    [dispatch],
  )

  useEffect(() => {
    if (keeId && isEmpty(keeDetails)) {
      fetchKeeDetails(keeId)
    }
  }, [keeId, keeDetails, fetchKeeDetails])

  const { loading, data } = keeData

  return (
    <KeeDetailsWrapper>
      <Breadcrumb separator=">">
        <Breadcrumb.Item className="link">
          <NavLink to={`/site/${siteId}`}>KEE</NavLink>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Investigator Details</Breadcrumb.Item>
      </Breadcrumb>
      <KeeContent>
        <Spin className="loading" spinning={loading} />
        <Skeleton active loading={loading} />
        {!isEmpty(keeData.data) ? (
          <>
            <KeeContentHeader>
              <div>Name and Location</div>
              <div>Address</div>
              <div># of Trials</div>
              <div>Specialisation</div>
            </KeeContentHeader>
            <KeeListItem data={data} />
          </>
        ) : (
          <Empty description="Kee data unavailable" />
        )}
      </KeeContent>

      {activeTab === '1' && (
        <SectionDetails>
          <div className="title">Investigator Details</div>
          <div className="sub-title">
            To more about payments click on <strong>View Details</strong>
          </div>
        </SectionDetails>
      )}
      {activeTab === '2' && (
        <SectionDetails>
          <div className="title">Timeline</div>
          <div className="sub-title">
            Learn more about scientific activities
          </div>
        </SectionDetails>
      )}
      <TabsWrapper>
        <div className="source">
          <strong>Site Intel Source:</strong> Veeva Link
        </div>
        <Tabs defaultActiveKey={activeTab} onChange={handleTabChange}>
          <TabPane tab="Overview" key="1">
            <KeeOverview keeId={keeId} />
          </TabPane>
          <TabPane tab="Timeline" key="2">
            <KeeTimeline keeId={keeId} />
          </TabPane>
        </Tabs>
      </TabsWrapper>
    </KeeDetailsWrapper>
  )
}

export default memo(KeeDetails)
