import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Home from '../containers/Home'
import SiteDetails from '../containers/SiteDetails'
import AdminSiteDetails from '../containers/admin/SiteDetails'
import KeeDetails from '../containers/KeeDetails'
import SiteForm from '../containers/admin/SiteForm'
import Sites from '../containers/admin/Sites'
import AuthRoute from './AuthRoute'
import UserDetails from '../containers/admin/UserDetails/UserDetails'

const AppRoutes = ({ element }) => {

  return (
    <Router>
      <Routes>
        <Route element={<AuthRoute>{element}</AuthRoute>}>
          <Route index element={<Home />} />
          <Route exact path="site/:id" element={<SiteDetails />} />
          <Route
            exact
            path="site/:siteId/kee/:keeId"
            element={<KeeDetails />}
          />
        </Route>
        <Route element={<AuthRoute isAdminRoute>{element}</AuthRoute>}>
          <Route path="admin">
            <Route path="sites" element={<Sites />} />
            <Route path="site/:id" element={<AdminSiteDetails />} />
            <Route path="add/site" element={<SiteForm />} />
            <Route path="edit/site/:id" element={<SiteForm />} />
            <Route path='userdetails' element={<UserDetails />} />
          </Route>
        </Route>
      </Routes>
    </Router>
  )
}

export default AppRoutes
