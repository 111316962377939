const BASE_URL = process.env.REACT_APP_BASE_URL
const AUTH_BASE_URL = process.env.REACT_APP_AUTH_BASE_URL

export const API_URL = BASE_URL + '/pic/api/v1'
const appDefaults = {
  api: {
    baseURL: BASE_URL,
    authBaseUrl: AUTH_BASE_URL,
    auth: `${AUTH_BASE_URL}/identity/azure/login`,
    getIndications: `${API_URL}/indication/all`,
    getSites: `${API_URL}/sites/list/indication`,
    updateSite: `${API_URL}/sites/update/site`,
    getSiteKees: `${API_URL}/kee/site/id`,
    getKeeDetails: `${API_URL}/kee/id`,
    getKeeCollaborations: `${API_URL}/kee/collaborations/id`,
    getSiteDetails: `${API_URL}/sites/list/site_id`,
    getKeeTimeLineData: `${API_URL}/kee/timeline/id`,
    getBCWheelData: `${API_URL}/sites/wheel_data`,
    getWheelData:
        'https://api.dev-fargate.azbrain.astrazeneca.com/pic/api/v1/wheel/accounts',
    getSiteCountries: `${API_URL}/sites/country_list`,
    getSitesByCountry: `${API_URL}/sites/list/country`,
    getAZStudies: `${API_URL}/clinicaltrails/az_studies`,
    getSiteNetworkData: `${API_URL}/sites/site_network`,
    getUserDetails: API_URL + "/users/prid_id",
    UserSave: `${API_URL}/sites/user/save`,
    UserDelete: `${API_URL}/sites/user/delete`,
    getUsers: `${API_URL}/sites/users`,
    getUsersRoles: `${API_URL}/sites/user/roles`
  },
}

export default appDefaults
