import { useEffect, memo } from 'react'
import { Layout, Breadcrumb, BackTop } from 'antd'
import { Outlet, useLocation } from 'react-router-dom'
import Header from './components/Header'
import Footer from './components/Footer'
import './App.css'

const { Content } = Layout
sessionStorage.clear()

const App = () => {
  const location = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location])

  return (
    <Layout className="layout">
      <Header />
      <Content>
        <Breadcrumb style={{ margin: '16px 0' }}>
          <Breadcrumb.Item></Breadcrumb.Item>
        </Breadcrumb>
        <div className="site-layout-content">
          <Outlet />
        </div>
      </Content>
      <Footer />
      <BackTop />
    </Layout>
  )
}

export default memo(App)
